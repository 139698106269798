import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SockethelperService } from '../socket/sockethelper.service';
import { Constants } from './Constants';
import { CookieStore } from '../helpers/CookieStore';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpClient: HttpClient, private socketService: SockethelperService) { }

  async getAllInviteUsers(){
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
         
           'authorization': CookieStore.getBearerToken()
        }),
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.USERS_SRV() + '/invite/list', {}, httpOptions).toPromise();
    if (response.resolver === 'http') {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.

  }

  async inviteUser(data: any){
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
         
           'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.USERS_SRV() + '/invite/init', data, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.

  }

  async searchUser(){
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
         
           'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.USERS_SRV() + 'search', {}, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.

  }

  async userInfo(data){
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
         
           'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.USERS_SRV() + '/userinfo/byadmin', data, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.

  }

  async assignGroups(data: any){
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
         
           'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.USERS_SRV() + '/groups/assign', data, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.

  }


  // New service

  async getAllUserList(){
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
         
           'authorization': CookieStore.getBearerToken()
        }),
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.USERS_SRV() + 'search', {}, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.

  }

  // search by role

  async searchByRole(userRole){
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
         
           'authorization': CookieStore.getBearerToken()
        }),
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.USERS_SRV() + 'search/' + `${userRole}`, {}, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.

  }

  // get user info by sub

  async getUserInfoBySub(data){
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
         
           'authorization': CookieStore.getBearerToken()
        }),
    };
    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.USERS_SRV() + 'userinfo', {}, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.

  }

  // get user info by sub by admin

  async getUserInfoBySubByAdmine(data){
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
         
           'authorization': CookieStore.getBearerToken()
        }),
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.USERS_SRV() + 'userinfo/byadmin', data, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.

  }

  // assign group and role

  async assignGroupAndRole(data){
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
         
           'authorization': CookieStore.getBearerToken()
        }),
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.USERS_SRV() + 'department/assign', data, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.

  }

  // invite User

  async inviteUsers(data){
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
           'authorization': CookieStore.getBearerToken()
        }),
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.USERS_SRV() + 'invite/init', data, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }
  }

  async getInvitedUser(){
      // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
         
           'authorization': CookieStore.getBearerToken()
        }),
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.USERS_SRV() + 'invite/list', {}, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.
}

}
