import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-complyment',
  templateUrl: './complyment.component.html',
  styleUrls: ['./complyment.component.scss']
})
export class ComplymentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 80); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

}
