import * as _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs/Observable';


export class Utils {

  static getMonths() {
    const months = _.times(12).map(i => moment().month(i).format('MMMM'));
    return months;
  }

  static getMonthCount() {
    return _.range(1,13);
  }

  static allMonths() {
    return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  }

  static getMonthFromArray(index) {
    let months =  ["","January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months[index];
  }

    /* format string first letter uppercase, remove _ and add space */
    static stringFormat(str:string) {
      let str1 = str;
        let string = str1.replace(/_/g, " ");
        return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
    }

    static getExtension(name) {
        // return name.slice((name.lastIndexOf('.') - 1 >>> 0) + 2);
        return name.substring(name.lastIndexOf(".") + 1);
    }

    static compareDate(date) {
        if (!moment(new Date(date)).isBefore()) {
            return true;
        } else {
            return false;
        }
    }

    static getIcon(extensoin) {
        let lower_extension = _.lowerCase(extensoin);
        switch (lower_extension) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            return 'fa fa-file-image'
          case 'pdf':
            return 'fa fa-file-pdf'
            break;
          case 'xls':
          case 'xlsx':
            return 'fa fa-file-excel'
          case 'doc':
            return 'fa da-file-word'
          default:
            return 'fa fa-file-alt'
        }
      }

    
    static getColor(days: any) {
      let selectDays = parseInt(days);
      switch (true) {
        case selectDays >= 60:
          return '#999999'; // gray
        case selectDays >= 30 && selectDays <= 60:
          return '#22c03c'; //green
        case selectDays >= 15 && selectDays <= 30:
          return '#f5f912'; //yellow
        case selectDays > 3 && selectDays < 15:
          return '#f1b428'; //orange
        case selectDays < 3:
          return '#ee335e'; //red
        default:
          return 'gray';
      }
    }

    static getReminderColor(type) {
      switch(type) {
        case "meeting_reminder" :
            return '#58b779';
        case "checkpoint_reminder":
            return '#4f9ce6';
        default: 
          return 'gray';
      }
    }
}