import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../rest/Constants';
import { SockethelperService } from '../socket/sockethelper.service';
import { CookieStore } from '../helpers/CookieStore';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  room_info = this.socketService.joinRoom();
  httpOptions;

  createRoom() {
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();
    const headers = new HttpHeaders().set(Constants.AUTHORIZATION, localStorage.getItem('access_token'));
    headers.append('Content-Type', 'application/json');
    headers.append('x-room', room_info.room);
    this.httpOptions = {
      headers: headers,
      responceType: 'json'
    };
    return this.room_info;
  }

  constructor(private httpClient: HttpClient, private socketService: SockethelperService  ) {
  }



  // create audit
  async createAudit(data: any): Promise<any> {
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
           'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.AUDIT_SRV() + 'audit', data, httpOptions ).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.
    console.log('await for the socket result response and this step is OPTIONAL. ')
    const socketRespone = await this.socketService.awaitForResult(room_info);
    if (socketRespone.success === true) {
      return Promise.resolve(socketRespone);
    } else {
      return Promise.reject(socketRespone);
    }
  }

  // update audit
  async updateAudit(data: any, auditId: string): Promise<any>{

    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
           'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.put(Constants.AUDIT_SRV() + 'audit/' + `${auditId}`, data, httpOptions ).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.
    console.log('await for the socket result response and this step is OPTIONAL. ')
    const socketRespone = await this.socketService.awaitForResult(room_info);
    if (socketRespone.success === true) {
      return Promise.resolve(socketRespone);
    } else {
      return Promise.reject(socketRespone);
    }
  }

  // get all audit
  async getAllAudits(): Promise<any>{
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
           'authorization': CookieStore.getBearerToken(),
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.get(Constants.AUDIT_SRV() + 'audit' , httpOptions ).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.
    console.log('await for the socket result response and this step is OPTIONAL. ')
    const socketRespone = await this.socketService.awaitForResult(room_info);
    if (socketRespone.success === true) {
      return Promise.resolve(socketRespone);
    } else {
      return Promise.reject(socketRespone);
    }
  }


  // get auidt by id
  async getAuditById(auditId){
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
           'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.get(Constants.AUDIT_SRV() + 'audit/' + `${auditId}` , httpOptions ).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.
    console.log('await for the socket result response and this step is OPTIONAL. ')
    const socketRespone = await this.socketService.awaitForResult(room_info);
    if (socketRespone.success === true) {
      return Promise.resolve(socketRespone);
    } else {
      return Promise.reject(socketRespone);
    }
    // const headers = new HttpHeaders().set(Constants.AUTHORIZATION, localStorage.getItem('access_token'));
    // return this.httpClient.get(Constants.AUDIT_SRV() + 'audits/audit_id=' + `${auditId}` , ({ headers }) ).toPromise();
  }

  // delete audit
  async deleteAudit(auditId): Promise<any> {
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
           'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.delete(Constants.AUDIT_SRV() + 'audit' + `/${auditId}`, httpOptions ).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.
    console.log('await for the socket result response and this step is OPTIONAL. ')
    const socketRespone = await this.socketService.awaitForResult(room_info);
    if (socketRespone.success === true) {
      return Promise.resolve(socketRespone);
    } else {
      return Promise.reject(socketRespone);
    }
  }


  // delete audit
  async getRunningAudits(): Promise<any> {
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
           'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.get(Constants.AUDIT_SRV() + 'audit/reports/running', httpOptions ).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.
    console.log('await for the socket result response and this step is OPTIONAL. ')
    const socketRespone = await this.socketService.awaitForResult(room_info);
    if (socketRespone.success === true) {
      return Promise.resolve(socketRespone);
    } else {
      return Promise.reject(socketRespone);
    }
  }

  // close audit
  
  // create audit
  async closeAudit(id): Promise<any> {
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
           'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.AUDIT_SRV() + 'audit/close/' + id, {}, httpOptions ).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.
    console.log('await for the socket result response and this step is OPTIONAL. ')
    const socketRespone = await this.socketService.awaitForResult(room_info);
    if (socketRespone.success === true) {
      return Promise.resolve(socketRespone);
    } else {
      return Promise.reject(socketRespone);
    }
  }
  
  // create audit
  async reopenAudit(id): Promise<any> {
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
           'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.post(Constants.AUDIT_SRV() + 'audit/open/' + id, {}, httpOptions ).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }

    // 4. await for the socket result response and this step is OPTIONAL.
    console.log('await for the socket result response and this step is OPTIONAL. ')
    const socketRespone = await this.socketService.awaitForResult(room_info);
    if (socketRespone.success === true) {
      return Promise.resolve(socketRespone);
    } else {
      return Promise.reject(socketRespone);
    }
  }

  
  async getAuditEventList(auditId): Promise<any> {
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
          'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.get(Constants.TASK_EVENT() + '/utils/list/byaudit/' + auditId, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }
  }
  

}
