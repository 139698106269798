import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { UsersService } from 'src/app/services/rest/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/utils/utils';
import * as $ from 'jquery';
import { Cache } from 'src/app/services/helpers/storage.provider';
import moment from 'moment';
import { ProfileService } from 'src/app/services/rest/profile.service';
import { Constants } from 'src/app/services/rest/Constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { CommunicatorService } from 'src/app/services/helpers/communicator.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Cache({ pool: 'User' }) user: any;
  @Cache({ pool: 'UserDetail' }) userDetail: any;
  @Cache({ pool: 'role' }) role: any;
  userData: any;
  payload: any;
  userInfo: any;
  departments: any[] = [];
  taskList: any[] = [];
  loader: boolean = false;
  userTask: any;
  date: any;
  toDay: any;
  buttonDisplay: any;
  parseToken: any;
  file: any;
  filename: any;
  proPicture: any;

  constructor(private spinner: NgxSpinnerService, private toastr: ToasterService, private httpClient: HttpClient,
    private userService: UsersService, private communicatorService: CommunicatorService, private profileService: ProfileService) {
    this.parseToken = JSON.parse(atob(localStorage.getItem('access_token').split('.')[1]));
  }

  ngOnInit(): void {
    this.proPicture = this.userDetail && this.userDetail.picture ? this.getURL(this.userDetail.picture) : '../../assets/img/faces/6.jpg';
  }

  /** check if token is expire */
  isAuthenticated() {
    let exp = new Date(this.parseToken.exp * 1000);
    return !moment(exp).isBefore();
  }

  fileUploadData() {
    var i = $(this).prev('label').clone();
    var file = $('#fileUpload')[0].files[0];
    var filename = $('#fileUpload')[0].files[0].name;
    $(this).prev('label').text(filename);
    this.filename = filename;
    this.file = file;

    this.uploadProfilePicture();
  }

  getURL(filename) {
    var url = Constants.GetBaseURL() + 'files/' + filename + '?access_token=' + localStorage.getItem('access_token');
    return url;
  }

  async uploadProfilePicture() {
    // this.loader = true;
    const formData = new FormData();
    formData.append('file', this.file);
    // this.file.inProgress = true;
    try {
      this.profileService.updateProfilePicture(formData).then((res: any) => {
        if (res) {
          this.getUserDetail();
          this.toastr.success('Success', 'Successfully Changed Profile picture');
          // this.loader = false;
        } else {
          this.toastr.error('Error Occurred', 'Failed to Added Profile Picture');
        }
      });
    }
    catch (error) {
      console.log("error while adding  Profile Picture", error);
      // this.loader = false;
      this.toastr.error('Error Occurred', 'Failed to Added Profile Picture');
    }
  }

  stringFormat(str) {
    return Utils.stringFormat(str);
  }

  getUserDetail() {
    this.loader = true;
    try {
      let data = {
        sub: this.userDetail.sub
      }
      this.userService.getUserInfoBySub(data).then(res => {
        if (res) {
          this.userDetail = res.data;
          this.proPicture = this.userDetail && this.userDetail.picture ? this.getURL(this.userDetail.picture) : '../../assets/img/faces/6.jpg';
          this.communicatorService.broadcast('profile');
        }
        this.loader = false;
      });
    } catch (error) {
      console.log("error while getting user", error);
    }
  }
}
