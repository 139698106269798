import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { OthersService } from 'src/app/services/rest/others.service';


@Component({
  selector: 'app-add-tenant',
  templateUrl: './add-tenant.component.html',
  styleUrls: ['./add-tenant.component.scss']
})
export class AddTenantComponent implements OnInit {

  isSpinner: boolean = false;
  loader: boolean = false

  tenantList: any[] = []
  newTenant: any;
  selectedTenant: any = { name: '' };
  tenantSelected: any
  newTenantModal: boolean = false

  constructor(private toastr: ToasterService, private otherService: OthersService) { }

  ngOnInit(): void {

    this.getTenantsList()
  }
  getTenantsList() {
    this.loader = true
    try {
      this.otherService.getAllTenants().then(res => {
        if (res.code == 200) {
          this.reset()
          this.tenantList = res.data;
        }
        this.loader = false
      }, error => {
        this.loader = false
        this.toastr.error('Error Occurred', error);
      });
    }
    catch (error) {
      this.loader = false
      this.toastr.error('Error Occurred', 'Tenants are not available');
    }
  }
  addTenant() {
    if (this.newTenant == '' || this.newTenant == null) {
      return
    }
    this.loader = true
    let fd = new FormData()
    fd.append('tenant_name', this.newTenant)
    try {
      this.otherService.createTenant({ 'tenant_name': this.newTenant }).then(res => {
        if (res.code == 200) {
          console.log(res);
          this.toastr.success('Success', 'Tenant created successfully');
          this.reset()
          this.getTenantsList()
        this.loader = false
        }
      }, error => {
        this.loader = false
        this.toastr.error('Error Occurred', error);
      });
    }
    catch (error) {
      this.loader = false
      this.toastr.error('Error Occurred', 'Tenants are not created');
    }
  }
  updateTenant() {
    if (this.selectedTenant.tenant_name == '' || this.selectedTenant.tenant_name == null) {
      return
    }
    this.loader = true
    try {
      this.otherService.updateTenant({ 'tenant_name': this.selectedTenant.tenant_name }, this.selectedTenant._id).then(res => {
        if (res.code == 200) {
          console.log(res);
          this.toastr.success('Success', 'Tenant updated successfully');
          this.reset()
          this.getTenantsList()
        this.loader = false
        }
      }, error => {
        this.loader = false
        this.toastr.error('Error Occurred', error);
      });
    }
    catch (error) {
      this.loader = false
      this.toastr.error('Error Occurred', 'Tenants are not updated');
    }
  }
  deleteTenant() {
    this.loader = true
    try {
      this.otherService.deleteTenant(this.selectedTenant._id).then(res => {
        if (res.code == 200) {
          console.log(res);
          this.toastr.success('Success', 'Tenant Deleted successfully');
          this.reset()
          this.getTenantsList()
        this.loader = false
        }
      }, error => {
        this.loader = false
        this.toastr.error('Error Occurred', error);
      });
    }
    catch (error) {
      this.loader = false
      this.toastr.error('Error Occurred', 'Tenants are not Deleted');
    }
  }

  reset() {
    this.selectedTenant = { tenant_name: '' };
    this.newTenant = ''
  }

}
