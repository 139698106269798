import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { RiskMgmtService } from 'src/app/services/rest/risk-mgmt.service';

@Component({
  selector: 'app-map-data',
  templateUrl: './map-data.component.html',
  styleUrls: ['./map-data.component.scss']
})
export class MapDataComponent implements OnInit {

  selectedTar: any;
  selectedDest: any;
  themeList: any = [];
  domainList: any = [];

  themeId: any;
  domainId: any;


  constructor(private riskService: RiskMgmtService, private toastr: ToasterService,) { }

  ngOnInit(): void {
    this.getAllThemeDomains();
    this.getDomains();
  }


  validate() {
    if (this.selectedTar == null || this.selectedTar == undefined) {
      return true;
    }
    else if (this.selectedDest == null || this.selectedDest == undefined) {
      return true;
    }
    return false;
  }

  getAllThemeDomains() {
    this.riskService.themeWithDomains().subscribe(res => {
      this.themeList = res.data;

    }, error => {
      this.toastr.error('Error', 'Error in get threats');
    });
  }

  getDomains() {
    this.riskService.fetchDomains().subscribe(res => {
      if (res) {
        this.domainList = res.data;
        if (res.data.length > 0) {
          this.domainList = [];
          res.data.forEach((item: any) => {
            if (item.theme_id == "") {
              this.domainList.push(item);
            }
          })

        } else {
        }
      }
    }, error => {
      this.toastr.error('Error', 'Error while getting Domains');
    });
  }

  changeSelectTheme(data: any) {
    this.themeId = data;
  }
  changeSelectDomain(data: any) {
    this.domainId = data;
  }

  mapThemeAndDomain() {
    let data = {
      _id: this.domainId,
      id: this.domainId,
      theme_id: this.themeId
    }
    this.riskService.mapThemeDomain(this.domainId, data).subscribe((res: any) => {
      document.getElementById('closeThemeDomainModal').click();
      this.toastr.success('Success', 'Updated successfully');
      this.getDomains();
    }, error => {
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in Mapping');
    });
  }

}
