import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Cache } from 'src/app/services/helpers/storage.provider';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { OthersService } from 'src/app/services/rest/others.service';

@Component({
  selector: 'app-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss']
})
export class TenantListComponent implements OnInit {

  @Cache({ pool: 'UserDetail' }) userDetail: any;
  // modalRef: BsModalRef;

  questionName: any;
  loader: boolean = false;
  isSpinner: boolean = false;
  payload: any;
  selectedQuestion: any;
  // newThemeForm: FormGroup;
  tenantList: any[] = [
    // {name: 'IT Consultant', createdBy: 'Vimal Prakash', id: 123, _id: 123},
  ];
  @ViewChild('template') template: TemplateRef<any>;
  // @ViewChild('modaldemo3') modaldemo3: ModalDirective;

  isQuestionModal: boolean = false;
  // private modalService: BsModalService,
  constructor(private router: Router, private toastr: ToasterService,
    private route: ActivatedRoute, private otherService: OthersService) { }

  ngOnInit(): void {
    this.getTenantList();
  }


  getTenantList() {
    this.loader = true;
    this.otherService.getAllTenants().then(res => {
      this.tenantList = res.data;
      this.isSpinner = false;
      this.loader = false;
    }, error => {
      this.isSpinner = false;
      this.loader = false;
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in fetch tenants');
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }

}
