import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './controllers/page-not-found/page-not-found.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AuthguardGuard } from './router/guads/auth.guard';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { AuthComponent } from './controllers/auth/auth.component';
import { ProfileComponent } from './controllers/profile/profile.component';
import { DataLoaderComponent } from './controllers/data-loader/data-loader.component';
import { ComplymentComponent } from './controllers/complyment/complyment.component';
import { ControlListComponent } from './controllers/control-list/control-list.component';
import { SetupStandardComponent } from './controllers/new-standards/setup-standard/setup-standard.component';
import { StandardListComponent } from './controllers/new-standards/standard-list/standard-list.component';
import { CreateStandardComponent } from './controllers/new-standards/create-standard/create-standard.component';
import { BusinessListComponent } from './controllers/risk/business-list/business-list.component';
import { DomainListComponent } from './controllers/risk/domain-list/domain-list.component';
import { CreateRiskComponent } from './controllers/risk/create-risk/create-risk.component';
import { CreateBusinessComponent } from './controllers/risk/create-business/create-business.component';
import { ItPolicyCreateComponent } from './controllers/it-policy/it-policy-create/it-policy-create.component';
// import { ItPolicyViewComponent } from './controllers/it-policy/it-policy-view/it-policy-view.component';
import { ItPolicyCreateThemeComponent } from './controllers/it-policy/it-policy-create-theme/it-policy-create-theme.component';
import { ItPolicyRoutesComponent } from './controllers/it-policy/it-policy-routes/it-policy-routes.component';
import { MapDataComponent } from './controllers/risk/map-data/map-data.component';
import { AddLogoComponent } from './controllers/others/add-logo/add-logo.component'
import { AddTenantComponent } from './controllers/others/add-tenant/add-tenant.component';
import { DueQuestionnaireComponent } from './controllers/due/due-questionnaire/due-questionnaire.component';
import { TenantListComponent } from './controllers/others/tenant-list/tenant-list.component';



// New Component
export const standardsRoute: Routes = [
  { path: '', redirectTo: 'list', pathMatch: 'full' },
  { path: 'list', component: StandardListComponent, data: { title: 'Standard List' } },
  { path: 'create', component: CreateStandardComponent, data: { title: 'Create Standard' } },
  { path: 'update/:standard_id', component: CreateStandardComponent, data: { title: 'Create Standard' } },
  { path: 'setup', component: SetupStandardComponent, data: { title: 'Setup Standard' } },
  { path: 'controls/:standard_id', component: ControlListComponent },
  { path: 'controls', component: ControlListComponent },
];

export const complyRoutes: Routes = [
  { path: '', redirectTo: 'standard', pathMatch: 'full' },
  {
    path: 'standard',
    loadChildren: () => import('./controllers/new-standards/standards.module').then(m => m.StandardsModule),
    data: { breadcrumb: 'Standards' }
  },
  {
    path: 'risks',
    loadChildren: () => import('./controllers/risk/risk.module').then(m => m.RiskModule)
  },

  {
    path: 'itpolicies', component: ItPolicyRoutesComponent,
    loadChildren: () => import('./controllers/it-policy/it-policy.module').then(m => m.ItPolicyModule)
    // data: { breadcrumb: 'Standards' }
  },
  {
    path: 'others',
    loadChildren: () => import('./controllers/others/others.module').then(m => m.OthersModule)
  },
  {
    path: 'due',
    loadChildren: () => import('./controllers/due/due.module').then(m => m.DueModule)
  }
];

export const othersRoute: Routes = [
  { path: '', redirectTo: 'add-logo', pathMatch: 'full' },
  { path: 'add-logo', component: AddLogoComponent },
  { path: 'add-tenant', component: AddTenantComponent },
  { path: 'tenant-list', component: TenantListComponent },
]

export const dueRoute: Routes = [
  { path: '', redirectTo: 'questionnaire', pathMatch: 'full' },
  { path: 'questionnaire', component: DueQuestionnaireComponent },
]

export const riskRoute: Routes = [
  { path: '', redirectTo: 'business', pathMatch: 'full' },
  { path: 'business', component: BusinessListComponent, data: { title: 'Assessment List', breadcrumb: 'Assessment List' } },
  { path: 'business/create', component: CreateBusinessComponent, data: { title: 'Assessment List', breadcrumb: 'Assessment List' } },
  { path: 'business/create/:id', component: CreateBusinessComponent, data: { title: 'Assessment List', breadcrumb: 'Assessment List' } },
  { path: 'domains', component: DomainListComponent, data: { title: 'Assessment List', breadcrumb: 'Assessment List' } },
  { path: 'create', component: CreateRiskComponent, data: { title: 'Assessment List', breadcrumb: 'Assessment List' } },
  { path: 'update/:riskId', component: CreateRiskComponent, data: { title: 'Assessment List', breadcrumb: 'Assessment List' } },
  { path: 'map-data', component: MapDataComponent }
];

// routes for the it policies 
export const itpolicies: Routes = [
  { path: '', redirectTo: 'create', pathMatch: 'full' },
  { path: 'create', component: ItPolicyCreateComponent },
  // { path: 'view', component: ItPolicyViewComponent },
  { path: 'create-theme', component: ItPolicyCreateThemeComponent },
];

const routes: Routes = [
  {
    path: 'complyment', component: ComplymentComponent,
    loadChildren: () => import('./controllers/complyment/complyment.module').then(m => m.ComplymentModule)
  },

  { path: 'profile', component: ProfileComponent },
  { path: "load", component: DataLoaderComponent, },
  { path: 'handle/token', component: LandingPageComponent, },
  { path: '404', component: PageNotFoundComponent, },
  { path: 'auth-error', component: AccessDeniedComponent },
  { path: 'auth', component: AuthComponent },
  { path: '', redirectTo: '/load', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
  // { path: 'billing', component: }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
