export class Constants {

  // public static BASE_URL = 'https://accounts-api.complyment.com/';

  public static AUTHORIZATION = "authorization";

  public static GetBaseURL() {
    let protocol = location.protocol;
    let domain = 'complyment';
    const current_location = location.host;
    if (!current_location.includes("localhost")) {
      domain = current_location.split(".")[1];
      if (domain != 'complyment' && domain != 'complyment-local') {
        domain = 'skill-mine';
      }
    }
    if (current_location.includes("localhost")) {
      protocol = "https:"
    }

    // const BASE_URL = protocol + '//{{tenant_group_key}}-api.' + domain + '.com/';
    const BASE_URL = protocol + '//{{tenant_group_key}}-api.' + domain + '.com/';
    if (!current_location.includes("localhost")) {
      let tenant_group = "demo";
      tenant_group = current_location.split(".")[0];
      return BASE_URL.replace("{{tenant_group_key}}", tenant_group);
    } else {
      // let tenant_group = "icici";
      //let tenant_group = "managementtest";
      let tenant_group = "management";
      return BASE_URL.replace("{{tenant_group_key}}", tenant_group);
    }
  }

  // public static GetBaseURL() {
  //   let BASE_URL = '';
  //   if (location.host == "managementtest.complyment.com" || location.host.includes('localhost')) {
  //   BASE_URL = 'https://managementtest-api.complyment.com/';
  //   } else {
  //   BASE_URL = 'https://management-api.complyment.com/';
  //   }
  //   const current_location = location.host;
  //   let tenant_group = "management";
  //   if (!current_location.includes("localhost")) {
  //     tenant_group = current_location.split(".")[0];
  //   }
  //   // return BASE_URL.replace("{{tenant_group_key}}", tenant_group);
  //   return BASE_URL;
  // }

  public static GetAuthBaseURL() {
    let BASE_URL = '';
    if (location.host == "managementtest.complyment.com" || location.host.includes('localhost')) {
      BASE_URL = 'https://managementtest-accounts-api.complyment.com';
    } else {
      BASE_URL = 'https://management-accounts-api.complyment.com';
    }
    const current_location = location.host;
    let tenant_group = "management";
    if (!current_location.includes("localhost")) {
      tenant_group = current_location.split(".")[0];
    }
    // const auth_base_url = BASE_URL.replace("{{tenant_group_key}}", tenant_group);
    const auth_base_url = BASE_URL;
    return auth_base_url;
  }
  // new services path

  public static STAND_SRV() {
    return this.GetBaseURL() + 'standards-srv/';
  }
  public static AUDIT_SRV() {
    return this.GetBaseURL() + 'audit-srv/';
  }
  public static AUDITTASKS_SRV() {
    return this.GetBaseURL() + 'audit-tasks-srv/task/';
  }
  public static USERS_SRV() {
    return this.GetBaseURL() + 'users-srv/users/';
  }
  public static NOTIFICATION_SRV() {
    return this.GetBaseURL() + 'notification-srv/notification';
  }
  public static PROFILE_SRV() {
    return this.GetBaseURL() + 'users-srv/users/userinfo/change/profile/picture';
  }
  public static PROFILE_PICTURE() {
    return this.GetBaseURL() + 'files/user/profile/';
  }
  public static TASK_EVENT() {
    return this.GetBaseURL() + 'audit-task-events-srv/event';
  }
  public static DOMAIN_SRV() {
    return this.GetBaseURL() + 'risk-domain-master-srv/domain';
  }
  public static ASSESSMENT_SRV() {
    return this.GetBaseURL() + 'risk-srv/assessment';
  }
  public static BUSINESS_SRV() {
    return this.GetBaseURL() + 'risk-business-type-master-srv/businesstype';
  }
  public static RISK_MGMT_SRV() {
    return this.GetBaseURL() + 'risk-management-master-srv/risk';
  }
  public static RISK_SRV() {
    return this.GetBaseURL() + 'risk-srv/riskitem';
  }
  public static MATRIX_SRV() {
    return this.GetBaseURL() + 'risk-matrix-master-srv/matrix';
  }
  public static THREAT_SRV() {
    return this.GetBaseURL() + 'risk-threat-master-srv/threat';
  }
  public static VUL_SRV() {
    return this.GetBaseURL() + 'risk-vulnerability-master-srv/vulnerability';
  }

  public static ITPolicy() {
    return this.GetBaseURL() + 'it-policies-srv/management/';
  }
  public static OTHERS() {
    return this.GetBaseURL() + 'management/tenant/';
  }

  public static DueDiligence() {
    return this.GetBaseURL() + 'due-diligence/management';
  }

  // create theme 

  // /risk-theme-master-srv/theme
  public static THEME_RISK() {
    return this.GetBaseURL() + 'risk-theme-master-srv/';
  }



  // create domain theme
  public static CREATE_DOMAIN_THEME() {
    return this.GetBaseURL() + 'risk-domain-master-srv/';
  }

  // risk theme 
  public static RISK_THEME() {
    return this.GetBaseURL() + 'risk-theme-master-srv/theme/';
  }


}
