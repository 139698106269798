import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SidebarService } from 'src/app/services/helpers/sidebar.service';
import * as _ from 'lodash';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { Cache } from 'src/app/services/helpers/storage.provider';
import { UsersService } from 'src/app/services/rest/users.service';
import { Utils } from 'src/app/utils/utils';
import { Constants } from 'src/app/services/rest/Constants';
import { CommunicatorService } from 'src/app/services/helpers/communicator.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Cache({ pool: 'User' }) user: any;
  @Cache({ pool: 'UserDetail' }) userDetail: any;
  @Cache({ pool: 'role' }) role: any;

  linkActive: boolean = false;
  sidebarMenu: any[];
  menus: any[] = [];
  departments: any;
  username: any;
  proImage: any;

  textLength: number = 25;
  constructor(private activateRouter: ActivatedRoute, private router: Router, private sidebarService: SidebarService,
    private titleService: Title, private userService: UsersService, private communicatorService: CommunicatorService) {
    // this.username = JSON.parse(localStorage.getItem('User')).userInfo.name;
    // this.proImage = this.userDetail.picture ? this.getURL(this.userDetail.picture) : 'assets/img/default-icon.jpg';
    // this.communicatorService.on('profile', (res) => {
    //   this.proImage = this.userDetail.picture ? this.getURL(this.userDetail.picture) : 'assets/img/default-icon.jpg';
    // });

  }

  stop(event) {

  }

  getURL(filename) {
    var url = Constants.GetBaseURL() + 'files/' + filename + '?access_token=' + localStorage.getItem('access_token');
    return url;
  }

  ngOnInit(): void {
    // Dynamic Title
    // this.getTitle();
    this.getData();
    // this.setActiveMenu();
  }

  getData() {
    this.menus = [];
    this.sidebarService.getJSON().subscribe(res => {
      if (res) {
        // this.menus = res.sidebar;
        let complyments = [];
        let risks = [];
        let itpolicy = [];
        let others = [];
        let due = [];
        res.sidebar.forEach(data => {
          if (data.category == 'complyment') {
            complyments.push(data);
          } else if (data.category == 'risk') {
            risks.push(data);
          }
          else if (data.category == 'itpolicy') {
            itpolicy.push(data);
          }
          else if (data.category == 'others') {
            others.push(data)
          }
          else if (data.category == 'due_diligence') {
            due.push(data)
          }
        });
        // console.log("window.location.href", window.location.href);
        // if(window.location.href.indexOf("https://nasscom.complyment.com") > -1 || window.location.href .indexOf("http://localhost:4200") > -1) {
        // }
        this.menus.push({ category: 'Compliance', values: complyments });
        this.menus.push({ category: 'Risk', values: risks });
        this.menus.push({ category: 'IT policy', values: itpolicy });
        this.menus.push({ category: 'Due Diligence', values: due });
        this.menus.push({ category: 'Others', values: others });
      }
    });
  }

  setActiveOnClick(name: string, item) {
    this.menus.forEach(data => {
      if (item.category == data.category) {
        item.values.forEach((menu) => {
          if (menu.name == name) {
            menu.active = true;
          } else {
            menu.active = false;
          }
        })
      } else {
        data.values.forEach((menu) => {
          menu.active = false;
        });
      }
    });
  }

  setActiveMenu() {
    this.menus.forEach((value) => {
      value.values.forEach((menu => {
        if (menu.router == this.router.url) {
          menu.active = true;
        } else {
          menu.active = false;
        }
      }));
    })
  }

  // activeParent(menu) {
  //   let findMenu = _.find(this.menus, ['parent', menu.parent]);
  //   if (findMenu) {
  //     findMenu.active = true;
  //   } else {
  //     _.forEach(this.menus, (find) => {
  //       if (find.name != menu.name) {
  //         find.active = false;
  //       }
  //     });
  //   }
  // }

  getTitle() {
    const appTitle = this.titleService.getTitle();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activateRouter.firstChild;

        while (child.firstChild) {
          child = child.firstChild;
        }
        if (child.snapshot.data['title']) {
          return child.snapshot.data['title'];
        }
        return appTitle;
      })
    ).subscribe((ttl: string) => {
      this.titleService.setTitle(ttl);
    })
  }

  slicer(text) {
    return text.length > this.textLength ? text.slice(0, this.textLength) + '...' : text;
  }

  stringFormat(str) {
    return Utils.stringFormat(str);
  }

}
