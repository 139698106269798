import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeAgoPipe } from './time-ago.pipe';
import { FilterByPipe } from './filter-by.pipe';
import { LimitAgoPipe } from './limit-ago.pipe';



@NgModule({
  declarations: [TimeAgoPipe, FilterByPipe, LimitAgoPipe],
  imports: [
    CommonModule
  ],
  exports: [TimeAgoPipe, FilterByPipe, LimitAgoPipe]
})
export class PipesModule { }
