import { Component, OnInit } from '@angular/core';
import { StandardService } from 'src/app/services/rest/standard.service';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-setup-standard',
  templateUrl: './setup-standard.component.html',
  styleUrls: ['./setup-standard.component.scss']
})
export class SetupStandardComponent implements OnInit {
  loader: boolean = false;
  standards: any[] = [];
  standardForm: FormGroup;
  standardId: any;
  payload: any = {};
  constructor(private standardService: StandardService, private toastr: ToasterService,
    private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {

    this.standardForm = this.fb.group({
      standard_name: new FormControl('', Validators.required),
      description: new FormControl(''),
      derived_from: new FormControl('', Validators.required),
      version: 'v1'
    });

  }

  ngOnInit(): void {
    this.getAllStandards();
  }

  async getAllStandards() {
    this.loader = true;
    try {
      this.standardService.getAllMasterStandard().then(res => {
        if (res) {
          this.standards = res.data;
        }
        this.loader = false;
      });
    }
    catch (error) {
      this.loader = false;
      console.log(error);
      this.toastr.error('Error Occurred', 'Standards are not available');
    }
  }

  async cloneStandards() {
    this.loader = true;
    this.standardId = this.standardForm.value.derived_from;
    try {
      this.standardService.cloneStandard(this.standardForm.value, this.standardId).then(res => {
        if (res) {
          this.toastr.success('Success', 'Standard Created Successfully');
          this.router.navigate(['../controls', res.data.id], { relativeTo: this.route });
        }
        this.loader = false;
      });
    }
    catch (error) {
      this.loader = false;
      console.log(error);
      this.toastr.error('Error Occurred', 'Failed to clone Standard');
    }
  }

  goToList() {
    this.router.navigate(['../list'], { relativeTo: this.route });
  }

}
