import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SockethelperService } from '../socket/sockethelper.service';
import { Constants } from './Constants';
import { CookieStore } from '../helpers/CookieStore';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  counter: number;
  count: BehaviorSubject<number>;

  constructor(private http: HttpClient, private socketService: SockethelperService) {
    this.count = new BehaviorSubject(this.counter);
   }

   setCount(count) {
    this.counter = count;
   }

   getCount() {
    this.count.next(this.counter);
   }

  /* notificaiton count */
  async getNotifyCount(): Promise<any> {
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
          'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.http.get(Constants.NOTIFICATION_SRV() + '/unread/count', httpOptions).toPromise();
    if (response.resolver == "http" || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success == true) {

        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }
    // 4. await for the socket result response and this step is OPTIONAL.
    const socketRespone = await this.socketService.awaitForResult(room_info);
    if (socketRespone.success === true) {
      return Promise.resolve(socketRespone);
    } else {
      return Promise.reject(socketRespone);
    }
  }

  /* notificaiton list */
  async getAllNotification(): Promise<any> {
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
          'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.http.get(Constants.NOTIFICATION_SRV(), httpOptions).toPromise();
    if (response.resolver == "http" || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success == true) {

        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }
    // 4. await for the socket result response and this step is OPTIONAL.
    console.log('await for the socket result response and this step is OPTIONAL. ')
    const socketRespone = await this.socketService.awaitForResult(room_info);
    if (socketRespone.success === true) {
      return Promise.resolve(socketRespone);
    } else {
      return Promise.reject(socketRespone);
    }
  }

  /* notificaiton list */
  async changeNotifyStatus(data): Promise<any> {
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
          'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.http.put(Constants.NOTIFICATION_SRV() + '/change/status', data, httpOptions).toPromise();
    if (response.resolver == "http") {
      this.socketService.leaveRoom(room_info);
      if (response.success == true) {

        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }
    // 4. await for the socket result response and this step is OPTIONAL.
    console.log('await for the socket result response and this step is OPTIONAL. ')
    const socketRespone = await this.socketService.awaitForResult(room_info);
    if (socketRespone.success === true) {
      return Promise.resolve(socketRespone);
    } else {
      return Promise.reject(socketRespone);
    }
  }

  /* notificaiton list */
  async updateMarkRead(id): Promise<any> {
    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'x-room': room_info.room,
          'authorization': CookieStore.getBearerToken()
        }),
      responceType: 'json'
    };

    // 3. Call the original rest api
    const response: any = await this.http.put(Constants.NOTIFICATION_SRV() + '/' + id, {}, httpOptions).toPromise();
    if (response.resolver == "http" || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success == true) {

        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }
  }


}
