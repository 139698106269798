import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  toastOptions: any;
  constructor(private toastr: ToastrService) {
    this.toastOptions = {
      timeOut: 2000,
      closeButton: true,
      positionClass: 'toast-bottom-right',
      opacity: 1,
    };
  }

  success(title, msg?) {
    this.toastr.success(msg ? msg : '', title, this.toastOptions);
  }

  error(title, msg) {
    this.toastr.error(msg, title, this.toastOptions);
  }

  info(title, msg) {
    this.toastr.info(msg, title, this.toastOptions);
  }
}
