import * as cookies from 'browser-cookies';

export class CookieStore {

    static saveBearerToken(token: string) {
        this.clearBearerToken();
        if (localStorage) {
            localStorage.setItem('access_token', token);
        } else {
            cookies.set("access_token", token);
        }
    }

    static getBearerToken(): string | null {
        if (localStorage && localStorage.getItem('access_token')) {
            return 'Bearer ' + localStorage.getItem('access_token');
        } else {
            const cookie = cookies.get('access_token');
            if (cookie) {
                return 'Bearer ' + cookie;
            }
            return null;
        }

    }

    static clearBearerToken() {
        if (localStorage) {
            localStorage.removeItem('access_token');
        } else {
            cookies.set("access_token", null);
        }
    }


}
