import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Cache } from 'src/app/services/helpers/storage.provider';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { DueMgmtService } from 'src/app/services/rest/due-mgmt.service';

export interface Domain {
  name: string;
  createdBy: string;
  sub: string;
}

@Component({
  selector: 'app-due-questionnaire',
  templateUrl: './due-questionnaire.component.html',
  styleUrls: ['./due-questionnaire.component.scss']
})
export class DueQuestionnaireComponent implements OnInit {

  @Cache({ pool: 'UserDetail' }) userDetail: any;
  // modalRef: BsModalRef;

  questionName: any;
  loader: boolean = false;
  isSpinner: boolean = false;
  payload: any;
  selectedQuestion: any;
  // newThemeForm: FormGroup;
  questionList: any[] = [
    // {name: 'IT Consultant', createdBy: 'Vimal Prakash', id: 123, _id: 123},
  ];
  @ViewChild('template') template: TemplateRef<any>;
  // @ViewChild('modaldemo3') modaldemo3: ModalDirective;

  isQuestionModal: boolean = false;
  // private modalService: BsModalService,
  constructor(private router: Router, private toastr: ToasterService,
    private route: ActivatedRoute, private dueService: DueMgmtService) { }

  ngOnInit(): void {
    this.getQuestionType();
  }

  goToCreate() {
    this.router.navigate(['./create'], { relativeTo: this.route });
  }

  goToEdit(data) {
    this.router.navigate(['./create', data.id], { relativeTo: this.route });
  }

  setPayload() {
    this.payload = {
      questionnaire: this.questionName,
      sub: this.userDetail.sub,
      createdBy: this.userDetail.name,
    }
  }

  getQuestionType() {
    this.loader = true;
    this.dueService.fetchQuestions().subscribe(res => {
      this.questionList = res.data;
      this.isSpinner = false;
      this.loader = false;
    }, error => {
      this.isSpinner = false;
      this.loader = false;
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in fetch question type');
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }


  // create theme
  createNewQuestion() {
    this.isSpinner = true;
    this.setPayload();
    this.dueService.createQuestion(this.payload).subscribe(res => {
      this.getQuestionType();
      this.isSpinner = false;
      this.questionName = '';
      this.toastr.success('Success', 'Question created successfully');
    }, error => {
      this.isSpinner = false;
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in creating question type');
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }

  editQuestion(question) {
    this.selectedQuestion = question;
  }


  // update theme
  updateQuestion() {
    this.isSpinner = true;
    this.dueService.updateQuestion(this.selectedQuestion._id, this.selectedQuestion).subscribe(res => {
      this.getQuestionType();
      this.isSpinner = false;
      this.toastr.success('Success', 'Updated successfully');
      this.selectedQuestion = null;
    }, error => {
      this.selectedQuestion = null;
      this.isSpinner = false;
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in updating question');
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }

  changeQuestionActive(event, question) {
    let payload = {
      is_active: event.target.checked
    }
    this.isSpinner = true;
    this.dueService.updateQuestion(question._id, payload).subscribe(res => {
      this.getQuestionType();
      this.isSpinner = false;
      this.toastr.success('Success', 'Updated successfully');
      this.selectedQuestion = null;
    }, error => {
      this.selectedQuestion = null;
      this.isSpinner = false;
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in updating question');
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }


  deleteQuestion() {
    this.dueService.deleteQuestion(this.selectedQuestion._id).subscribe(res => {
      this.getQuestionType();
      this.toastr.success('Success', 'Deleted successfully');
      this.selectedQuestion = null;
    }, error => {
      this.selectedQuestion = null;
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in delete question');
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }



}
