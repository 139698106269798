import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Cache } from 'src/app/services/helpers/storage.provider';
import { RiskMgmtService } from 'src/app/services/rest/risk-mgmt.service';
// import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
// import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { FormGroup } from '@angular/forms';

export interface Domain {
  name: string;
  createdBy: string;
  sub: string;
}

@Component({
  selector: 'app-business-list',
  templateUrl: './business-list.component.html',
  styleUrls: ['./business-list.component.scss']
})

export class BusinessListComponent implements OnInit {
  @Cache({ pool: 'UserDetail' }) userDetail: any;
  // modalRef: BsModalRef;

  businessName: any;
  loader: boolean = false;
  isSpinner: boolean = false;
  payload: any;
  selectedBusiness: any;
  // newThemeForm: FormGroup;
  businessList: any[] = [
    // {name: 'IT Consultant', createdBy: 'Vimal Prakash', id: 123, _id: 123},
  ];
  @ViewChild('template') template: TemplateRef<any>;
  // @ViewChild('modaldemo3') modaldemo3: ModalDirective;

  isBusinessModal: boolean = false;
  // private modalService: BsModalService,
  constructor( private router: Router, private toastr: ToasterService,
    private route: ActivatedRoute, private riskService: RiskMgmtService) { }

  ngOnInit(): void {
    this.getBusinessType();
  }

  goToCreate() {
    this.router.navigate(['./create'], { relativeTo: this.route });
  }

  goToEdit(data) {
    this.router.navigate(['./create', data.id], { relativeTo: this.route });
  }

  setPayload() {
    this.payload = {
      name: this.businessName,
      sub: this.userDetail.sub,
      createdBy: this.userDetail.name,
    }
  }

  getBusinessType() {
    this.loader = true;
    this.riskService.fetchThemes().subscribe(res => {
      this.businessList = res.data;
      this.isSpinner = false;
      this.loader = false;
    }, error => {
      this.isSpinner = false;
      this.loader = false;
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in fetch business type');
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }


  // create theme
  createNewTheme() {
    this.isSpinner = true;
    this.setPayload();
    this.riskService.createNewTheme(this.payload).subscribe(res => {
      this.getBusinessType();
      this.isSpinner = false;
      this.businessName = '';
      this.toastr.success('Success', 'Theme created successfully');
    }, error => {
      this.isSpinner = false;
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in creating business type');
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }


  // update theme
  updateTheme() {
    this.isSpinner = true;
    this.riskService.updateTheme(this.selectedBusiness._id, this.selectedBusiness).subscribe(res => {
      this.getBusinessType();
      this.isSpinner = false;
      this.toastr.success('Success', 'Updated successfully');
      this.selectedBusiness = null;
    }, error => {
      this.selectedBusiness = null;
      this.isSpinner = false;
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in creating business type');
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }

  deleteBusiness() {
    this.riskService.deleteTheme(this.selectedBusiness._id).subscribe(res => {
      this.getBusinessType();
      this.toastr.success('Success', 'Deleted successfully');
      this.selectedBusiness = null;
    }, error => {
      this.selectedBusiness = null;
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in delete business type');
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }



}
