import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Complyment Business';
  viewSite: boolean = true;
  token = localStorage.getItem('access_token');
  parseToken: any;

  constructor() {
    this.toParseToken();

  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 80); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

    /** parse token if token is not null else redirect to login page */
  toParseToken() {
    if(this.token && this.token != ' ') {
      this.viewSite = true;
    } else {
      this.viewSite = false;
    }
  }

  isAuthenticated() {
    this.parseToken = JSON.parse(atob(localStorage.getItem('access_token').split('.')[1]));
    let exp = new Date(this.parseToken.exp*1000);
    return !moment(exp).isBefore();
}


}
