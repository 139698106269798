
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StandardService } from 'src/app/services/rest/standard.service';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cache } from 'src/app/services/helpers/storage.provider';
import * as _ from 'lodash';

@Component({
  selector: 'app-standard-list',
  templateUrl: './standard-list.component.html',
  styleUrls: ['./standard-list.component.scss']
})
export class StandardListComponent implements OnInit {
  @Cache({ pool: 'role' }) role: any;

  standards: any[] = [];
  textLength: number = 202;
  date: any;
  standardList: any[] = [];
  selectedStandard: any;
  loader: boolean = false;
  viewType: any = 'grid';
  customStandards: any[] = [];
  masterStandards: any[] = [];
  showAll: boolean = true;
  deletedStandards: any[] = [];
  is_active: boolean = true;
  is_deleted: boolean = false;
  is_finalised: boolean = true;
  filterList: any[] = [
    { name: 'Active', id: 'is_active', value: true },
    { name: 'Finalised', id: 'is_finalised', value: true },
    { name: 'Deleted', id: 'is_deleted', value: true }
  ];
  selectedFilter: any;

  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToasterService,
    private standardService: StandardService, private spinner: NgxSpinnerService) {
    this.standards = [];
    this.selectedFilter = this.filterList[0].id;
  }

  ngOnInit(): void {
    // this.getCustomStandards();
    // this.getMasterStandards();
    this.filterStandard();
    // this.getAllStandards();
  }

  filterStandard() {
    // switch(this.selectedFilter) {
    //   case 'all':
    //     this.is_active = true;
    //     this.is_deleted = false;
    //     this.is_finalised = true;
    //     break;
    //   case 'is_active':
    //     this.is_active = true;
    //     this.is_deleted = false;
    //     this.is_finalised = false;
    //     break;
    //   case 'is_finalised':
    //     this.is_active = false;
    //     this.is_deleted = false;
    //     this.is_finalised = true;
    //     break;
    //   case 'is_deleted':
    //     this.is_active = false;
    //     this.is_deleted = true;
    //     this.is_finalised = false;
    //     break;
    // }
    this.getAllStandards();
  }

  getAllStandards() {
    this.standards = [];
    let param = '?' + this.selectedFilter + '=' + true;
    this.standardService.allStandards(param).subscribe(res => {
      this.standards = res.data;
    }, error => {
      console.log("error", error);
      this.toastr.error('Error', 'Error while getting standards');
    });
  }

  async getMasterStandards() {
    this.loader = true;
    try {
      this.standardService.getAllMasterStandard().then(res => {
        if (res) {
          this.masterStandards = res.data;
          this.deletedStandards = _.filter(res.data, ['is_deleted', true]);
          this.standards = this.masterStandards;
        }
        this.loader = false;
      });
    }
    catch (error) {
      this.loader = false;
      console.log(error);
      this.toastr.error('Error Occurred', 'Standards are not available');
    }
  }

  async getCustomStandards() {
    this.loader = true;
    try {
      this.standardService.getAllCustomStandard().then(res => {
        if (res) {
          this.customStandards = res.data;
          this.setStandardView();
        }
        this.loader = false;
      });
    }
    catch (error) {
      this.loader = false;
      console.log(error);
      this.toastr.error('Error Occurred', 'Standards are not available');
    }
  }

  setStandardView() {
    this.standards = this.masterStandards;
  }

  goToEdit(id) {
    this.router.navigate(['../update', id], { relativeTo: this.route });
  }

  goToCreate() {
    this.router.navigate(['../create'], { relativeTo: this.route });
  }

  goToStandard(standard) {

    this.router.navigate(['../controls', standard.id], { relativeTo: this.route, queryParams: { name: standard.standard_name } });
  }

  goToManage(standard) {
    this.router.navigate(['../view-control', standard.id], { relativeTo: this.route });
  }
  typeView() {

    if (this.viewType === 'grid') {

      localStorage.setItem('viewType', this.viewType);
      this.viewType = 'table';

    }
    else {

      localStorage.setItem('viewType', this.viewType);
      this.viewType = 'grid';

    }

  }

  goToSetup() {
    this.router.navigate(['../setup'], { relativeTo: this.route });
  }

  slicer(text) {
    return text.length > this.textLength ? text.slice(0, this.textLength) + '...' : text;
  }


}
