import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { ITPolicies } from 'src/app/services/rest/itpolicies-services.service';
import { ToasterService } from 'src/app/services/helpers/toaster.service';

@Component({
  selector: 'app-it-policy-create-theme',
  templateUrl: './it-policy-create-theme.component.html',
  styleUrls: ['./it-policy-create-theme.component.scss']
})
export class ItPolicyCreateThemeComponent implements OnInit {


  theme: any = [];



  themeData = {
    theme_name: ''
  };


  updateData = {
    updateThemeName: ''
  };



  editThemeData: any = {
    theme_name: ''
  };

  deleteData: any = {
    theme_name: ''
  }
  data1 = [{
    'slno': '1',
    'name': 'Access Control',
    'level': '7',
    'criteraia': 'High',
    'identification_date': '12-10-2021',
    'end_date': '12-10-2021',
    'risk_owner': 'Anand',
    'report': 'up',
    'treatment': 'Avoid',
    'status': 'In Progress',
    'expanded': false,
    'dept_id': 'admin'
  }, {
    'slno': '2',
    'name': 'Access Control',
    'level': '7',
    'criteraia': 'High',
    'identification_date': '12-10-2021',
    'end_date': '12-10-2021',
    'risk_owner': 'Anand',
    'treatment': 'Accept',
    'report': 'up',
    'status': 'In Progress',
    'expanded': false,
    'dept_id': 'infosec'
  }, {
    'slno': '3',
    'name': 'Access Control',
    'level': '7',
    'criteraia': 'High',
    'identification_date': '12-10-2021',
    'end_date': '12-10-2021',
    'risk_owner': 'Anand',
    'treatment': 'Mitigate',
    'report': 'xl',
    'status': 'Closed',
    'expanded': false,
    'dept_id': 'itapp'
  },
  {
    'slno': '4',
    'name': 'Access Control',
    'level': '7',
    'criteraia': 'High',
    'identification_date': '12-10-2021',
    'end_date': '12-10-2021',
    'risk_owner': 'Anand',
    'treatment': 'Transfer',
    'status': 'Closed',
    'report': 'xl',
    'expanded': false,
    'dept_id': 'infra'
  },
  ]

  constructor(private http: HttpClient, private itpolicies: ITPolicies, private toastr: ToasterService) { }

  ngOnInit(): void {
    this.getAllThemes();
  }

  onSubmit(form, themeData) {

  }


  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        // 'x-room': room_info.room,
        'authorization': CookieStore.getBearerToken()
      }),
    responceType: 'json'
  };


  // create new theme 
  addTheme() {
    this.itpolicies.createNewTheme(this.themeData).subscribe(res => {
      this.themeData.theme_name = "";
      document.getElementById('closeAdd').click();
      this.toastr.success('Success', 'Theme Added Successfully');
      this.getAllThemes();
    }, error => {
      this.toastr.error('Error', 'Error');
      console.log('failed ----', error);
    });
  }


  getAllThemes() {
    this.itpolicies.getAllCreatedThemes().subscribe(res => {
      this.theme = res.data;
    }, error => {
      // this.toastr.error('Error', 'Error in get threats');
    });
  }


  editTheme(data) {
    this.editThemeData = data;
  }

  updateNewTheme() {
    // this.threatsList = [];
    this.itpolicies.updateTheme(this.editThemeData._id, { theme_name: this.editThemeData.theme_name }).subscribe(res => {
      this.toastr.success('Success', 'Updated Theme successfully');
      this.getAllThemes();
    }, error => {
      this.toastr.error('Error', 'Error');
    });
  }


  deleteTheme(data) {
    this.deleteData = data;
  }

  // delete created theme 
  deleteCreatedTheme() {
    this.itpolicies.deleteTheme(this.deleteData._id).subscribe(res => {
      this.getAllThemes();
      this.toastr.success('Success', 'Deleted successfully');
    }, error => {
      this.toastr.error('Error', 'Error');
    });
  }

}


