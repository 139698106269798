<!-- <app-splash-loader *ngIf="loader"></app-splash-loader> -->
<div class="container-fluid">

	<!-- breadcrumb -->
	<div class="breadcrumb-header justify-content-between">
		<div class="my-auto">
			<div class="d-flex">
				<h4 class="content-title mb-0 my-auto">Pages</h4><span class="text-muted mt-1 tx-13 ml-2 mb-0">/
					Profile</span>
			</div>
		</div>
	</div>
	<!-- breadcrumb -->

	<!-- row -->
	<div class="row row-sm">
		<div class="col-lg-4">
			<div class="card mg-b-20">
				<div class="card-body">
					<div class="pl-0" *ngIf="userDetail">
						<div class="main-profile-overview">
							<div class="main-img-user profile-user pos-relative">
								<form>
									<!-- ../../assets/img/faces/6.jpg -->
									<div *ngIf="proPicture" class="bgpro-img" 
									[ngStyle]="{'background-image': 'url(' + proPicture + ')'  }"></div>
									<!-- <img alt="" [src]="proPicture"> -->
									<label for="fileUpload" class="upload-icon">
										<i class="fas fa-camera profile-edit text-primary no-margin"></i>
									</label>
									<input id="fileUpload" (change)="fileUploadData()" name='fileUpload' #fileUpload
										type="file" style="display:none;">
								</form>
							</div>
							<div class="d-flex justify-content-between mg-b-20">
								<div>
									<h5 class="main-profile-name">{{userDetail.name}}</h5>
									<p class="main-profile-name-text">{{userDetail.email}}</p>
								</div>
							</div>
							<h6>Role</h6>
							<div class="row d-flex flex-row justify-content-start mg-t-10">
								<div class="col-md-4 col mb20 ">

									<h6 class="text-small text-muted mb-0" *ngIf="role">
										{{stringFormat(role)}}</h6>
								</div>
							</div>
						</div><!-- main-profile-overview -->
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-8">
			<div class="card">
				<div class="card-body" *ngIf="userDetail">
					<div class="tabs-menu ">
						<!-- Tabs -->
						<ul class="nav nav-tabs profile navtab-custom panel-tabs mg-l-0">
							<li class="active">
								<a href="#settings" data-toggle="tab" aria-expanded="false"> <span class="visible-xs"><i
											class="las la-cog tx-16 mr-1"></i></span> <span
										class="hidden-xs">SETTINGS</span> </a>
							</li>
						</ul>
					</div>
					<div class="tab-content border-left border-bottom border-right mg-t-0 border-top-0 p-4">

						<div class="tab-pane active" id="settings">
							<form role="form">
								<div class="form-group">
									<label for="FullName">Name</label>
									<input type="text" value="{{userDetail.given_name}}" id="FullName"
										class="form-control">
								</div>
								<div class="form-group">
									<label for="FullName">Family Name</label>
									<input type="text" value="{{userDetail.family_name}}" id="family_name"
										class="form-control">
								</div>
								<div class="form-group">
									<label for="Email">Email</label>
									<input type="email" value="{{userDetail.email}}" id="Email" class="form-control">
								</div>
								<button class="btn btn-primary waves-effect waves-light w-md"
									type="submit">Save</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- row closed -->
</div>
<!-- Container closed -->