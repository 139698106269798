<!-- main-sidebar -->
<div class="app-sidebar__overlay" data-toggle="sidebar"></div>
<aside class="app-sidebar sidebar-scroll">
  <div class="main-sidebar-header active">
    <a class="desktop-logo logo-light active" href="index.html"
      ><img
        src="../../../assets/img/logo_main.png"
        class="main-logo"
        alt="logo"
    /></a>
    <a class="desktop-logo logo-dark active" href="index.html"
      ><img
        src="../../../assets/img/Logo_white.png"
        class="main-logo dark-theme"
        alt="logo"
    /></a>
    <a class="logo-icon mobile-logo icon-light active" href="index.html"
      ><img src="../../assets/img/logo_symbol.png" class="logo-icon" alt="logo"
    /></a>
    <a class="logo-icon mobile-logo icon-dark active" href="index.html"
      ><img
        src="../../assets/img/brand/favicon-white.png"
        class="logo-icon dark-theme"
        alt="logo"
    /></a>
  </div>
  <div class="main-sidemenu">
    <ul class="side-menu pd-t-30">
      <ng-container *ngFor="let menu of menus">
        <li class="side-item side-item-category">{{ menu.category }}</li>
        <li class="slide" *ngFor="let side of menu.values">
          <a
            class="side-menu__item"
            routerLink="{{ side.routerLink }}"
            (click)="setActiveOnClick(side.name, menu)"
          >
            <!-- <i
              class="side-menu__icon {{ side.icon }}"
              [ngClass]="{ 'active-class': side.active }"
              viewBox="0 0 24 24"
            ></i> -->
            <!--  src="../../../assets/img/sidebarIcons/{{ side.icon_img }}" -->
            <img
              src="../../../assets/sidebarIcons/{{ side.imgIcon }}"
              alt=""
              class="mx-2 _sidemenuimg"
            />
            <span
              class="side-menu__label"
              [ngClass]="{ 'active-class': side.active }"
              >{{ side.name }}
            </span>
            <span class="badge badge-danger side-badge" *ngIf="side.new"
              >New</span
            >
          </a>
        </li>
      </ng-container>
    </ul>
    <!-- Footer opened -->
    <!-- <app-footer></app-footer> -->
    <!-- Footer closed -->
  </div>
</aside>

<!-- main-sidebar -->
