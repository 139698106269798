
		<!-- Page -->
		<div class="page">
		
			<!-- Main-error-wrapper -->
			<div class="main-error-wrapper  page page-h ">
				<img src="../../assets/img/media/500.png" class="error-page" alt="error">
				<h2>You dont have access to this functionality.</h2>
				<!-- <a class="btn btn-outline-danger" [routerLink]="['/complyment']" >Back to HomeBack</a> -->
			</div>
			<!-- /Main-error-wrapper -->
			
		</div>
		<!-- End Page -->
