import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthinterceptorInterceptor implements HttpInterceptor {
  token: any;
  exp: any;

  constructor(private router: Router, private toast: ToastrService) {
    this.token = localStorage.getItem('access_token');
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // return next.handle(request);
    if (this.token) {
      return next.handle(request).pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) { this.handleError(err);return next.handle(request); }
          return new Observable<HttpEvent<any>>();
        })
      )
    } else {
      this.router.navigate(['/auth']);
      return;
    }
  }

  private handleError(error) {
    switch (error.status) {
      case 401:
      case 403:
        console.log('Session Expired. Redirect to login', error);
        this.toast.error('Unauthorized', 'you are not authorized for this function');
        localStorage.removeItem('access_token');
        this.router.navigate(['/auth']);
        break;
      case 500:
        console.log('Something broke from server. Show 500 page', error);
        // this.toast.error('Error Occured', '500 | INTERNAL SERVER ERROR');
        break;
      case 409:
        console.error('Intercepted Error', error);
        break;
      case 404:
        console.error('Service Not found', error);
        break;
      default:
        console.log("error", error.status + '|' + error.statusText, error.message);
        // this.toast.error(error.status + '|' + error.statusText, error.message);
    }
    return error;
  }
}
