<!-- Loader -->
<!-- Page -->
<div class="page" id="top">
  <!-- /Loader -->
  <!-- main-sidebar -->
  <app-sidebar></app-sidebar>
  <!-- main-sidebar -->

  <!-- main-content -->
  <div class="main-content app-content">
    <!-- main-header -->
    <app-header></app-header>
    <!-- /main-header -->

    <div
      class="jumps-prevent d-sm-none d-md-block"
      style="padding-top: 63px"
    ></div>
    <!-- container -->
    <!-- <div class="container-fluid"> -->
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <!-- </div> -->
    <!-- /Container -->
  </div>
  <!-- /main-content -->

  <!-- Footer opened -->
  <!-- <div class="main-footer ht-40">
    <app-footer></app-footer>
  </div> -->
  <!-- Footer closed -->
</div>
<!-- End Page -->
