import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationMsgService {

  constructor() { }

  public getValidationMsg(validationId: string, error_type: string, field_name: string, error_obj: any, custom_message: any): string {
    // console.log(error_type);
    // console.log(field_name);
    // console.log("", error_obj);
    if (this.errorMessages[validationId]) {
      return this.errorMessages[validationId];
    } else if (this.errorMessages[error_type]) {
      var msg = "";
      if (custom_message && custom_message[error_type]) {
        return custom_message[error_type];
      }
      if (error_type == "required") {
        msg = this.errorMessages["required"];
      } else if (error_type == "minlength" && error_obj) {
        const requiredLength = error_obj["requiredLength"];
        msg = this.errorMessages[error_type].replace("{val}", requiredLength);
      } else if (error_type == "maxlength" && error_obj) {
        const requiredLength = error_obj["requiredLength"];
        msg = this.errorMessages[error_type].replace("{val}", requiredLength);
      } else if (error_type == "min" && error_obj) {
        const requiredLength = error_obj["min"];
        msg = this.errorMessages[error_type].replace("{val}", requiredLength);
      } else if (error_type == "max" && error_obj) {
        const requiredLength = error_obj["max"];
        msg = this.errorMessages[error_type].replace("{val}", requiredLength);
      } else if (error_type == "max" && error_obj) {
        const requiredLength = error_obj["max"];
        msg = this.errorMessages[error_type].replace("{val}", requiredLength);
      } else if (error_type == "pattern" && error_obj) {
        const requiredLength = error_obj["requiredPattern"];
        msg = this.errorMessages[error_type].replace("{val}", requiredLength);
      } else if (error_type == "email") {
        msg = this.errorMessages[error_type];
      }




      return msg;
    } else {
      return "";
    }
  }

  private errorMessages = {
    'required': "This field is a required field",
    'minlength': "This field required atleast {val} chars",
    'maxlength': "This field allowed only {val} chars",
    'email': "Please enter valid email",
    'min': "Value must be greater or equal to {val}",
    'max': "Value must be less than or equal to {val}",
    'pattern': "Value must match folowing pattern {val}",
    'nullValidator': "Value must match folowing pattern {val}",

  }

}
