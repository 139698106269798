import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { LocationHelper } from 'src/app/services/helpers/LocationHelper';
import { Constants } from 'src/app/services/rest/Constants';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  token: any;
  exp: any;
  parseToken: any;

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  redirectLogin() {
    localStorage.clear();
    this.router.navigate(['/load']);
  }

  ngOnInit(): void {
    this.redirectLogin();
  }

  /** set user sub and roles to localstorage */

}
