import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { LoginComponent } from './controllers/login/login.component';
import { SidebarService } from './services/helpers/sidebar.service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PageNotFoundComponent } from './controllers/page-not-found/page-not-found.component';
import { ValidationMsgService } from './services/validation/validation-msg.service';
import { AuditService } from './services/rest/audit.service';
import { ToasterService } from './services/helpers/toaster.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { AuthComponent } from './controllers/auth/auth.component';
import { ProfileComponent } from './controllers/profile/profile.component';
import { AuthinterceptorInterceptor } from './router/interceptors/authinterceptor.interceptor';
import { PipesModule } from './pipes/pipes.module';
import { NotificationService } from './services/rest/notification.service';


/* external packages */
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { DataLoaderComponent } from './controllers/data-loader/data-loader.component';
import { environment } from '../environments/environment';
import { CommunicatorService } from './services/helpers/communicator.service';

@NgModule({
  declarations: [
    AppComponent,
    // LoginComponent,
    PageNotFoundComponent,
    LandingPageComponent,
    AccessDeniedComponent,
    AuthComponent,
    ProfileComponent,
    DataLoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    PipesModule
  ],
  exports: [
    // FormcontrolValidationDirective,
    // FormsubmitValidationDirective,

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthinterceptorInterceptor,
      multi: true
    },
    SidebarService,
    HttpClient,
    ValidationMsgService,
    NgxSpinnerService,
    AuditService,
    ToasterService,
    NotificationService,
    CommunicatorService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
