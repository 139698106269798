import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RiskMgmtService } from 'src/app/services/rest/risk-mgmt.service';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { Cache } from 'src/app/services/helpers/storage.provider';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { UsersService } from 'src/app/services/rest/users.service';



@Component({
  selector: 'app-domain-list',
  templateUrl: './domain-list.component.html',
  styleUrls: ['./domain-list.component.scss']
})
export class DomainListComponent implements OnInit {
  @Cache({ pool: 'UserDetail' }) userDetail: any;





  assessmentId: any;
  domainList: any[] = [];
  data: any[] = [];
  selectedQue: any = {};
  selectedDomain: any = null;
  domainName: any;
  themeSelect: any;
  payload: any = {};
  isSpinner: boolean = false;
  selectedRisk: any;
  loader: boolean = false;
  selectedLabel: any = 'risk';
  selectedTab: any = 'risk';
  vulnerability: any;
  threat: any;
  threatsList: any[] = [];
  vulnerabilityList: any[] = [];
  selectedThreat: any;
  selectedThreatData: any;
  selectedVulData: any;
  selectedVul: any;

  theme: any = [];

  // domain = {
  //   domainName:'',
  //   theme:''
  // };


  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToasterService,
    private riskService: RiskMgmtService, private http: HttpClient, private user: UsersService) {

    this.domainList = [];
    this.data = [];
  }

  ngOnInit(): void {
    // this.getRouterParam();
    this.getDomains();


    // theme and domains
    this.getAllThemeDomains();
  }






  checkTab() {
    if (this.selectedTab == 'risk') {
      this.goToCreate();
    }
  }

  getDomains() {
    this.loader = true;
    this.riskService.fetchDomains().subscribe(res => {
      if (res) {
        this.domainList = res.data;
        if (res.data.length > 0) {
          this.domainList[0].active = true;
          this.selectedDomain = this.domainList[0];
          this.getRiskByDomain();

        } else {
          this.loader = false;
        }
      }
    }, error => {
      this.loader = false;
      this.toastr.error('Error', 'Error while getting Domains');
    });
  }

  getRiskByDomain() {
    this.data = [];
    this.riskService.fetchRiskByDomainId(this.selectedDomain._id).subscribe(res => {
      this.getAllVul();
      this.getAllThreat();
      this.data = res.data;
      this.loader = false;
    }, error => {
      this.loader = false;
      this.toastr.error('Error', 'Error while getting Risk Item');
    })
  }

  setPayload() {
    this.payload = {
      name: this.domainName,
      domains: this.domainList,
      created_by: {
        name: this.userDetail.name,
        unique_id: this.userDetail.sub
      }
    }
  }



  createDomain(form) {
    // alert('Form submitted successfully');

    let payload = form

    this.riskService.createThemeDomain(payload).subscribe(res => {
      this.getAllThemeDomains();
      this.toastr.success('Success', 'theme domain created successfully');
    }, error => {
      this.toastr.error('Error', 'Error in create vul');
    });
  }

  updateDomain() {
    this.isSpinner = true;
    this.riskService.updateDomain(this.selectedDomain._id, this.selectedDomain).subscribe(res => {
      this.getDomains();
      this.isSpinner = false;
      this.toastr.success('Success', 'Updated successfully');
      this.selectedDomain = null;
    }, error => {
      this.selectedDomain = null;
      this.isSpinner = false;
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in creating business type');
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }

  deleteDomain() {
    this.riskService.deleteDomain(this.selectedDomain._id).subscribe(res => {
      this.getDomains();
      this.toastr.success('Success', 'Deleted successfully');
      this.selectedDomain = null;
    }, error => {
      this.selectedDomain = null;
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in delete business type');
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }

  getRouterParam() {
    this.route.params.subscribe(res => {
      if (res && res.id) {
        this.assessmentId = res.assess_id;
      }
    });
  }

  goToAnswer(data) {
    this.router.navigate(['../update', data.id], { relativeTo: this.route });
  }

  goToCreate() {
    this.router.navigate(['../create'], { relativeTo: this.route, queryParams: { domainId: this.selectedDomain._id } });
  }

  deleteRiskItem() {
    this.riskService.deletRisk(this.selectedRisk.id).subscribe(res => {
      this.toastr.success('Success', 'Deleted successfully');
      this.getRiskByDomain();
    }, error => {
      this.toastr.error('Error', error.error ? error.error.error.error_description : 'Error in delete Risk');
    })
  }

  changeDomain(item) {
    this.selectedDomain = item;

    this.getRiskByDomain();
  }

  createVul() {
    let payload = {
      vulnerability: this.vulnerability,
      domain_id: this.selectedDomain._id,
      created_by: {
        name: this.userDetail.name,
        unique_id: this.userDetail.sub
      }
    }

    this.riskService.createVul(payload).subscribe(res => {
      this.vulnerability = '';
      this.getAllVul();
      this.toastr.success('Success', 'Vulnerability created successfully');
    }, error => {
      this.toastr.error('Error', 'Error in create vul');
    });
  }

  getAllVul() {
    this.vulnerabilityList = [];
    this.riskService.getAllVul(this.selectedDomain._id).subscribe(res => {
      this.vulnerabilityList = res.data;
    }, error => {
      this.toastr.error('Error', 'Error in get vulnerabilities');
    });
  }

  getAllThreat() {
    this.threatsList = [];
    this.riskService.getAllThreat(this.selectedDomain._id).subscribe(res => {
      this.threatsList = res.data;
    }, error => {
      this.toastr.error('Error', 'Error in get threats');
    });
  }

  createThreat() {
    let payload = {
      threat: this.threat,
      domain_id: this.selectedDomain._id,
      created_by: {
        name: this.userDetail.name,
        unique_id: this.userDetail.sub
      }
    }
    this.riskService.createThreat(payload).subscribe(res => {
      this.threat = '';
      this.toastr.success('Success', 'Threat created successfully');
      this.getAllThreat();
    }, error => {
      this.toastr.error('Error', 'Error in create vul');
    });
  }

  updateThreat() {
    this.riskService.updateThreat(this.selectedThreat._id, this.selectedThreatData).subscribe(res => {
      this.toastr.success('Success', 'Threat updated successfully');
      this.getAllThreat();
    }, error => {
      this.toastr.error('Error', 'Error in update threat');
    });
  }

  deleteThreat() {
    this.riskService.deletThreat(this.selectedThreat._id).subscribe(res => {
      this.toastr.success('Success', 'Threat deleted successfully');
      this.getAllThreat();
    }, error => {
      console.log("error delete", error);
      this.toastr.error('Error', error.error);
    })
  }
  // vul update

  selectThreat(item) {
    this.selectedThreat = item;
    this.getThreatById();
  }

  selectVul(item) {
    this.selectedVul = item;
    this.getVulById();
  }

  updateVal() {
    this.riskService.updateVul(this.selectedVul._id, this.selectedVulData).subscribe(res => {
      this.toastr.success('Success', 'Vulnerability updated successfully');
      this.getAllVul();
    }, error => {
      this.toastr.error('Error', 'Error in update vul');
    });
  }



  deleteVul() {
    this.riskService.deletVul(this.selectedVul._id).subscribe(res => {
      this.toastr.success('Success', 'Vulnerability deleted successfully');
      this.getAllVul();
    }, error => {
      console.log("error delete", error);
      this.toastr.error('Error', error.error);
    })
  }

  getThreatById() {
    this.riskService.getThreatById(this.selectedThreat._id).subscribe(res => {
      this.selectedThreatData = res.data;
    }, error => {
      this.toastr.error('Error', error.error);
    })
  }

  getVulById() {
    this.riskService.getVulById(this.selectedVul._id).subscribe(res => {
      this.selectedVulData = res.data;
    }, error => {
      this.toastr.error('Error', error.error);
    })
  }

  // all themes and domains
  getAllThemeDomains() {
    // this.threatsList = [];
    this.riskService.themeWithDomains().subscribe(res => {
      this.theme = res.data;
    }, error => {
      this.toastr.error('Error', 'Error in get threats');
    });
  }
}
