import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { SidebarService } from 'src/app/services/helpers/sidebar.service';
import { IFilterPipe } from 'src/app/pipes/filter-by.pipe';
import { NotificationService } from 'src/app/services/rest/notification.service';
import * as $ from 'jquery';
import { UsersService } from 'src/app/services/rest/users.service';
import { Cache } from 'src/app/services/helpers/storage.provider';
import { CommunicatorService } from 'src/app/services/helpers/communicator.service';
import { BehaviorSubject } from 'rxjs';
import { Constants } from 'src/app/services/rest/Constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Cache({ pool: 'UserDetail' }) userDetail: any;

  token: any;
  menus: any[] = [];
  viewType: any = 'Grid';
  searchList: any[] = [];
  searchTerm: any;
  filterTerm: IFilterPipe = {} as IFilterPipe;
  searchBoxOpen: boolean = false;
  notifyCount: any = 0;
  user: any;
  username: any;
  proImage: any;

  constructor(private router: Router, private route: ActivatedRoute, private titleService: Title, private communicatorService: CommunicatorService,
    private sidebarService: SidebarService, private notifyService: NotificationService, private userService: UsersService) {
    this.username = JSON.parse(localStorage.getItem('User')).userInfo.name;
    // this.proImage = this.userDetail.picture ? this.getURL(this.userDetail.picture) : 'assets/img/default-icon.jpg';
    // router.events.subscribe((val) => {
    //   this.communicatorService.on('notification', (res) => {
    //     this.getNotifyCount();
    //   });
    //   this.communicatorService.on('profile', (res) => {
    //     this.proImage = this.userDetail.picture ? this.getURL(this.userDetail.picture) : 'assets/img/default-icon.jpg';
    //   });
    // });
  }

  ngOnInit(): void {
    this.setTitle();
    // this.getNotifyCount();
  }

  getURL(filename) {
    var url = Constants.GetBaseURL() + 'files/' + filename + '?access_token=' + localStorage.getItem('access_token');
    return url;
  }

  setTitle() {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;

          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }

  clearSearch() {
    var $form = $(this).closest('form');
    $form.find('input').val('');
    // alert("close search");
    $('.main-header form[role="search"]').removeClass('active');

  }

  logout() {
    if (this.token) {
      localStorage.removeItem('access_token');
      this.router.navigate(['/auth']);
    }
  }

  getNotifyCount() {
    this.notifyService.getNotifyCount().then(res => {
      if (res) {
        this.notifyCount = res.data;
        this.notifyService.setCount(this.notifyCount);
      }
    });
  }

  goToNotify() {
    this.router.navigate(['../notification'], { relativeTo: this.route });
  }

  clear() {
    this.searchBoxOpen = false;
    this.searchTerm = '';
  }

  searchArray(array) {
    array.forEach((menu) => {
      if (menu.routerLink) {
        this.searchList.push({ name: menu.name, path: menu.routerLink });
      }
      if (menu.subMenus) {
        this.searchArray(menu.subMenus);
      }
    });
  }
}
