import { Injectable } from '@angular/core';
import { SockethelperService } from '../socket/sockethelper.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Constants } from './Constants';
import { CookieStore } from '../helpers/CookieStore';
import { Observable } from 'rxjs';
import { flatMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private httpClient: HttpClient, private socketService: SockethelperService) { }

  async updateProfilePicture(data) {

    // 1. Creates Unique Room Id and joins with socket hub
    const room_info = this.socketService.joinRoom();

    // 2. Takes the Room ID and set that as header to tell the backend what room the result should be commited
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'authorization': CookieStore.getBearerToken()
        }),
    };

    // 3. Call the original rest api
    const response: any = await this.httpClient.put(Constants.PROFILE_SRV() , data, httpOptions).toPromise();
    if (response.resolver === 'http' || response.resolver === undefined) {
      this.socketService.leaveRoom(room_info);
      if (response.success === true) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    }
  }

  getProfilePicture(sub) {
    let url = Constants.GetBaseURL() + "files/user/profile/" + sub;
    return this.httpClient.get(url, { headers:{"authorization": CookieStore.getBearerToken()}, responseType: 'blob' })
    .pipe(
      flatMap(blob => {
        return this.blobToBase64(blob);
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {return Observable.throw('error'); }
        else {
          return new Observable<any>();
        }
      })
    );
  }

  private blobToBase64(blob: Blob): Observable<any> {
    const fileReader = new FileReader();
    const observable = new Observable(observer => {
      fileReader.onloadend = () => {
        observer.next(fileReader.result);
        observer.complete();
      };
    });
    fileReader.readAsDataURL(blob);
    return observable;
  }
}
