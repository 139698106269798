import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { ITPolicies } from 'src/app/services/rest/itpolicies-services.service';

@Component({
  selector: 'app-it-policy-create',
  templateUrl: './it-policy-create.component.html',
  styleUrls: ['./it-policy-create.component.scss']
})
export class ItPolicyCreateComponent implements OnInit {


  showDivHeader;
  show = false;
  showIntro = false;
  showBody = false;
  showAncilary = false;
  showFooter = false;
  toView: any;

  loadComponent: boolean = false;
  htmlContentHeader: 'data';
  policy_data: any = [];
  childData: any;
  _policyHeader: any;
  theme = [];

  delValue: any;
  policy = {
    theme_id: '',
    policies_name: ''
  };

  selectThemeId: any;
  editPolicy: any;

  policyId: any;

  // policy for update
  header: any;
  introduction: any;
  body: any;
  anciliary_info: any;
  footer: any;


  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '250',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    sanitize: false
  };






  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        // 'x-room': room_info.room,
        'authorization': CookieStore.getBearerToken()
      }),
    responceType: 'json'
  };




  constructor(private route: Router, private toastr: ToasterService, private http: HttpClient, private itpolicies: ITPolicies) { }

  ngOnInit(): void {
    this.allPolicies();
  }


  policyForm = new FormGroup({
    theme_id: new FormControl(''),
    policies_id: new FormControl(''),
    header: new FormControl('', Validators.required),
    introduction: new FormControl('', Validators.required),
    body: new FormControl('', Validators.required),
    anciliary_info: new FormControl('', Validators.required),
    footer: new FormControl('', Validators.required)
  });



  allPolicies() {
    this.itpolicies.getAllPolicies().subscribe(res => {
      this.theme = res.data;
      this._policyHeader = this.theme[0].ListofThemePolicy[0].policies_name;
      this.selectThemeId = this.theme[0].ListofThemePolicy[0]._id;

      this.getPolicyData(this.theme[0].ListofThemePolicy[0]._id);

    }, error => {
      this.toastr.error('Error', 'Error');
    });
  }


  sendData(data: any) {
    this.childData = data;
    this._policyHeader = this.childData.policies_name;
    this.selectThemeId = this.childData._id;
    this.getPolicyData(this.childData._id);
  }


  createNewPolicy(form, policy) {
    this.itpolicies.createNewPolicy(policy).subscribe(res => {
      document.getElementById('closeCreatePolicyModal').click();
      this.toastr.success('Success', 'Created successfully');
      this.allPolicies();
    }, error => {
      this.toastr.error('Error', 'Error');
      console.log('failed ----', error);
    });
  }


  onSubmitForm() {
    this.policyForm.patchValue({ theme_id: this.childData.theme_id, policies_id: this.childData._id });
    let formData = JSON.stringify(this.policyForm.value);

    this.itpolicies.submitPolicy(this.policyForm.value).subscribe((res: any) => {

      this.policyForm.reset();
      this.show = false;
      this.showIntro = false;
      this.showBody = false;
      this.showAncilary = false;
      this.showFooter = false;
      this.toastr.success('Success', 'Created successfully');
      this.getPolicyData(this.childData._id);
    }, error => {
      this.toastr.error('Error', 'Error in get threats');
      console.log('failed ----', error);
    })

    console.warn('the form data for the policy is ----', this.policyForm.value);
  }


  // savePolicy(){
  //   this.policyForm.patchValue({theme_id:this.childData.theme_id, policies_id:this.childData._id });
  //   let formData = JSON.stringify(this.policyForm.value);
  //   // console.log('the form ddata ---->', formData);
  //   localStorage.setItem('form-data', formData)
  // }





  getPolicyData(id) {
    this.itpolicies.getPolicyById(id).subscribe(res => {
      this.policy_data = res.data;
      this.policyId = this.policy_data[0].policies_id;
    }, error => {
      // this.toastr.error('Error', 'Error in get threats');
    });
  }



  updatePolicy(data: any) {

    let formUpdate = {
      theme_id: data.theme_id,
      policies_id: data.policies_id,
      header: data.header,
      introduction: data.introduction,
      body: data.body,
      anciliary_info: data.anciliary_info,
      footer: data.footer
    }


    this.itpolicies.updatePolicy(data.policies_id, formUpdate).subscribe(res => {
      if (res) {
        this.show = false;
        this.showIntro = false;
        this.showBody = false;
        this.showAncilary = false;
        this.showFooter = false;
        this.toastr.success('Success', 'Updated successfully');
      }

      this.getPolicyData(this.childData._id);

    }, error => {
      this.toastr.error('Error', 'Error');
    });
  }



  openDelModal(data: any) {
    this.delValue = data;
  }

  // delete policy
  deletePolicyField() {
    let type = this.delValue;

    this.itpolicies.deletePolicyField(type, this.policyId).subscribe(res => {
      document.getElementById('closeDelModal').click();
      this.getPolicyData(this.policyId);
      this.toastr.success('Success', 'Deleted successfully');

    }, error => {
      this.toastr.error('Error', 'Error');
    });

  }

  validationUpdateForm(data: any) {
    if (data.header == '' || data.header == undefined) {
      return true;
    }
    else if (data.introduction == '' || data.introduction == undefined) {
      return true;
    }
    else if (data.body == '' || data.body == undefined) {
      return true;
    }
    else if (data.anciliary_info == '' || data.anciliary_info == undefined) {
      return true;
    }
    else if (data.footer == '' || data.footer == undefined) {
      return true;
    }
    else {
      return false;
    }
  }



  updatePolicyHeader() {
    let data = {
      policies_name: this._policyHeader,
    }
    this.itpolicies.updatePolicyHeader(this.selectThemeId, data).subscribe(res => {
      if (res) {
        document.getElementById('closeUpdatePolicyModal').click();
        this.toastr.success('Success', 'Updated successfully');
        this.allPolicies();
        this.getPolicyData(this.selectThemeId);
      }

    }, error => {
      this.toastr.error('Error', 'Error');
    });
  }



  deleteITPolicy() {
    this.itpolicies.deleteITPolicy(this.selectThemeId).subscribe(res => {
      document.getElementById('closeDelPolicyModal').click();
      this.toastr.success('Success', 'Deleted successfully');
      this.allPolicies();
      this.getPolicyData(this.selectThemeId);
    }, error => {
      this.toastr.error('Error', 'Error');
    });
  }

}
