import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, ActivatedRoute } from '@angular/router';
import { StandardService } from 'src/app/services/rest/standard.service';
import * as _ from 'lodash';
import { Cache } from 'src/app/services/helpers/storage.provider';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Utils } from 'src/app/utils/utils';

// import {TreeNode} from 'primeng/api';

export interface TreeNode {
  data?: any;
  children?: TreeNode[];
  leaf?: boolean;
  expanded?: boolean;
}

@Component({
  selector: 'app-control-list',
  templateUrl: './control-list.component.html',
  styleUrls: ['./control-list.component.scss']
})
export class ControlListComponent implements OnInit {
  allList: TreeNode[];
  nodes: any[] = [];
  selectedNode: TreeNode;
  //do something with selectedNode

  @Cache({ pool: 'complyRole' }) complyRole: any;
  showChildInfo: boolean = false;
  categoryList: any[] = [];
  controlList: any = [] = [];
  standard_id: any;
  standard_name: any;
  editedControl: any = {};
  editedCate: any = {};
  selectedControl: any = {};
  selectedCategoryId: any;
  selectedCategory: any;
  modalTitle: any;
  payload: any = {};
  type: any;
  editView: boolean = false;
  updateStdId: any;
  updateControlId: any;
  updatePayload: any;
  controlOrders: any[] = [];
  categoryOrders: any[] = [];
  loader: boolean = false;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '250',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    outline: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['undo',
        'redo',
        'italic',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontName'
      ],
      [
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
      ]
    ]
  };

  dataNew: any = {};
  data: any[] = [];
  eventContainerData: any = {};
  arrayNew: any = {};
  newOrders: any[] = [];
  orderArray: any[] = [];
  paylodData: any = {};
  payloadForOrder: any = {};
  readOnly: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private standardService: StandardService,
    private toastr: ToasterService) {
    this.categoryList = [];
    this.controlList = [];
    // if (Utils.checkRole('audit_owner')) {
    //   this.editView = true;
    // }
  }

  ngOnInit(): void {
    this.getRouteParam();
  }

  onEditControl(control) {
    this.editedControl = control;
  }

  onEditCategory(cate) {
    this.editedCate = cate;
  }

  addControl(type, index, control?) {
    this.selectedControl.order = index;
    this.selectedControl.is_customizable = true;
    this.selectedControl.is_mandatory = true;
    this.selectedControl.parent_control_id = this.selectedCategoryId;
    this.setControlPayload();
    this.modalTitle = 'Add Control';
    this.type = 'control';
  }

  addCategory(parentId, order?) {
    this.selectedControl.order = order ? order : this.categoryList.length + 1;
    this.selectedControl.parent_control_id = parentId;
    this.selectedControl.is_customizable = true;
    this.selectedControl.is_mandatory = true;
    if (parentId == 'root') {
      this.setCategoryPayload();
    }
    this.type = 'category';
  }

  setControlPayload() {
    let control_order = { order: this.selectedControl.order, control_id: '' }
    this.controlOrders = this.controlOrders.filter((item) => {
      if (item.order == this.selectedControl.order) {
        item = control_order;
      }
      if (item.control_id != this.selectedControl.order.control_id) {
        return item;
      }
    });
    this.selectedControl.orders = this.controlOrders;
  }

  setCategoryPayload() {
    let control_order = { order: this.selectedControl.order, control_id: '' }
    this.categoryOrders = this.categoryOrders.filter((item) => {
      if (item.order == this.selectedControl.order) {
        item = control_order;
      }
      if (item.control_id != this.selectedControl.order.control_id) {
        return item;
      }
    });
    this.selectedControl.orders = this.controlOrders;
  }

  getRouteParam() {
    this.route.queryParams.subscribe(res => {
      this.standard_name = res.name;
      if (res.readOnly) {
        this.readOnly = res.readOnly;
        this.editView = false;
      }
    })
    this.route.params.subscribe(res => {
      this.standard_id = res.standard_id;
      this.getCategoryByStd();
    })
  }

  merge(data) {
    let array = [];
    let result = data.reduce((res, elem) => {
      elem.controls.forEach((item) => {
        array.push(item);
      });
      return res = res.concat(array);
    }, []);

  }
  selectedCate(category, sub?) {
    this.selectedCategory = category;
    if (this.selectedCategory) {
      this.showChildInfo = true;
      this.selectedCategory.active = true;
      this.selectedCategoryId = category.control_id;
      this.getControlByStd(category.control_id);
    }
  }

  enableControl(control) {
    if (control.enabled) {
      this.getDisableControl(control);
    } else {
      this.getEnableControl(control);
    }

  }

  getEnableControl(control) {
    this.loader = true;
    try {
      this.standardService.enableControl(control.standard_id, control.control_id).then(res => {
        this.loader = false;
      }, error => {
        this.loader = false;
        console.log(error);
        this.toastr.error('Error', error.error.error.error_description);
      });
    }
    catch (error) {
      this.loader = false;
      console.log('error', error);
      this.toastr.error('Error Occurred', 'Failed to enable control');
    }

  }

  getDisableControl(control) {
    this.loader = true;
    try {
      this.standardService.disableControl(control.standard_id, control.control_id).then(res => {
        this.loader = false;
      }, error => {
        this.loader = false;
        console.log(error);
        this.toastr.error('Error', error.error.error.error_description);
      });

    }

    catch (error) {
      this.loader = false;
      console.log('error', error);
      this.toastr.error('Error Occurred', 'Failed to disable control');
    }
  }

  recursion(item) {

  }

  callRecursively(arrayVal: any[], item) {
    item.orders = [];
    arrayVal.forEach((value, index) => {
      item.orders.push({ order: index + 1, control_id: value.control_id })
      if (value.controls && value.controls.length > 0) {
        this.callRecursively(value.controls, value);
      }
    })
  }

  getCategoryByStd(end?) {
    try {
      this.loader = true;
      this.standardService.getCategoryListById(this.standard_id).then(res => {
        if (res && res.data) {
          this.categoryList = res.data;
          this.categoryOrders = []; this.allList = [];
          this.categoryList.forEach((item, index) => {
            this.categoryOrders.push({ order: index + 1, control_id: item.control_id });
            // this.callRecursively(item.controls, item);
            this.callRecursively(this.categoryList, item);
          });

          // this.categoryList.forEach(data => {
          //   this.allList.push({data: data, children: data.controls});
          // });
          this.selectedCategory = this.categoryList[0];
          this.constructNodes();
          this.selectedCate(this.categoryList[0]);
          // if (end) {
          //   let index = res.data.length - 1;
          //   this.categoryList[index].active = true;
          //   this.selectedCate(this.categoryList[index]);
          // }
          if (this.categoryList && this.categoryList.length > 0) {
            this.categoryList[0].active = true;
          }
        }
        this.loader = false;
      }, error => {
        this.loader = false;
        console.log(error);
        this.toastr.error('Error', error.error.error.error_description);
      });

    }
    catch (error) {
      this.loader = false;
      console.log('error', error);
      this.toastr.error('Error Occurred', 'Category is not available');
    }

  }

  getControlByStd(cateId) {
    this.loader = true;
    try {
      this.standardService.getControlListByCatId(this.standard_id, cateId).then(res => {
        this.controlOrders = [];
        this.controlList = res.data;
        this.controlList.forEach((control, index) => {
          this.controlOrders.push({ order: index + 1, control_id: control.control_id });
        });
        this.loader = false;
      }, error => {
        this.loader = false;
        console.log(error);
        this.toastr.error('Error', error.error.error.error_description);
      });

    }
    catch (error) {
      this.loader = false;
      console.log('error', error);
      this.toastr.error('Error Occurred', 'Category is not available');
    }

  }

  setUpdatePayload(category?) {
    if (category) {
      this.updateControlId = this.editedCate.control_id;
      this.updateStdId = this.editedCate.standard_id
      this.updatePayload = {
        control_text: this.editedCate.control_text,
      }
    } else {
      this.updateControlId = this.editedControl.control_id;
      this.updateStdId = this.editedControl.standard_id
      this.updatePayload = this.editedControl;
    }
  }

  updateControl() {
    this.loader = true;
    try {
      this.standardService.updateControl(this.editedControl.standard_id, this.editedControl.control_id, this.editedControl).then(res => {
        this.selectedControl = {};
        this.editedControl = {};
        this.controlOrders = [];
        if (res) {
          setTimeout(() => {
            this.getControlByStd(this.selectedCategoryId);
          }, 800);
        }
        this.loader = false;
        this.toastr.success('Success', 'Updated control successfully');
      }, error => {
        this.loader = false;
        console.log(error);
        this.toastr.error('Error', error.error.error.error_description);
      });
    } catch (error) {
      this.loader = false;
      console.log('error', error);
      this.toastr.error('Error Occurred', 'Category is not available');
    }
  }

  deleteCategory() {
    this.loader = true;
    try {
      this.standardService.deleteControl(this.selectedCategory.standard_id, this.selectedCategory.control_id).then(res => {
        this.editedControl = {};
        if (res) {
          setTimeout(() => {
            this.getCategoryByStd();
          }, 1000);
        }
        this.loader = false;
        this.toastr.success('Success', 'Deleted control successfully');
      }, error => {
        this.loader = false;
        console.log(error);
        this.toastr.error('Error', error.error.error.error_description);
      });
    }
    catch (error) {
      this.loader = false;
      console.log('error', error);
      this.toastr.error('Error Occurred', 'Category is not available');
    }
  }

  deleteControl() {
    this.loader = true;
    try {
      this.standardService.deleteControl(this.editedControl.standard_id, this.editedControl.control_id).then(res => {
        this.editedControl = {};
        if (res) {
          setTimeout(() => {
            this.getControlByStd(this.selectedCategoryId);
          }, 1000);
        }
        this.loader = false;
        this.toastr.success('Success', 'Deleted control successfully');
      }, error => {
        this.loader = false;
        console.log(error);
        this.toastr.error('Error', error.error.error.error_description);
      });
    }
    catch (error) {
      this.loader = false;
      console.log('error', error);
      this.toastr.error('Error Occurred', 'Category is not available');
    }
  }


  insertHelper(i) {
    if (this.selectedControl) {

      this.modalTitle = 'Add Control';
      this.type = 'control';
      this.selectedControl.order = i + 1;
      this.selectedControl.is_customizable = true;
      this.selectedControl.is_mandatory = true;
      this.selectedControl.parent_control_id = this.selectedCategoryId;
      let control_order = { order: this.selectedControl.order, control_id: '' };
      this.controlOrders.splice(i, 0, control_order);
      this.selectedControl.orders = [];
      this.controlOrders.forEach((order, index) => {
        if (order.control_id != '') {
          let newControlOrder = { order: index + 1, control_id: order.control_id };
          this.selectedControl.orders.push(newControlOrder);
        }
      });
    }
  }

  categoryHelper(i) {
    this.selectedControl.order = i + 1;
    this.selectedControl.is_customizable = true;
    this.selectedControl.is_mandatory = true;

    let categoryOrder = { order: this.selectedControl.order, control_id: '' };
    let allOrders = [];
    allOrders = _.cloneDeep(this.categoryOrders);
    allOrders.splice(i, 0, categoryOrder);
    this.selectedControl.orders = [];
    allOrders.forEach((order, index) => {
      if (order.control_id != '') {
        let newControlOrder = { order: index + 1, control_id: order.control_id };
        this.selectedControl.orders.push(newControlOrder);
      }
    });
  }

  insertAbove(index) {
    let i = index == 0 ? index : index - 1;
    this.insertHelper(i);
  }

  insertBelow(index) {
    let i = index + 1;
    this.insertHelper(i);
  }

  insertAboveCategory(index) {
    let i = index;
    if (this.selectedControl) {
      this.selectedControl.parent_control_id = 'root';
      this.categoryHelper(i);
    }
  }

  insertBelowCategory(index) {
    let i = index + 1;
    if (this.selectedControl) {
      this.selectedControl.parent_control_id = 'root';
      this.categoryHelper(i);
    }
  }

  insertSubAbove(index, orders, parentId) {
    let i = index == 0 ? index : index - 1;
    if (this.selectedControl) {
      this.selectedControl.parent_control_id = parentId;
      this.insertSubHelper(i, orders);
    }
  }

  insertSubBelow(index, orders, parentId) {
    let i = index + 1;
    if (this.selectedControl) {
      this.selectedControl.parent_control_id = parentId;
      this.insertSubHelper(i, orders);
    }
  }

  insertSubHelper(i, orders) {
    this.selectedControl.order = i + 1;
    this.selectedControl.is_customizable = true;
    this.selectedControl.is_mandatory = true;

    let categoryOrder = { order: this.selectedControl.order, control_id: '' };
    let allOrders = [];
    this.selectedControl.orders = [];
    if (orders && orders.length > 0) {
      allOrders = orders;
      allOrders.splice(i, 0, categoryOrder);
      allOrders.forEach((order, index) => {
        if (order.control_id != '') {
          let newControlOrder = { order: index + 1, control_id: order.control_id };
          this.selectedControl.orders.push(newControlOrder);
        }
      });
    } else {
      let newControlOrder = { order: this.selectedControl.order, control_id: this.selectedControl.control_id };
      this.selectedControl.orders.push(newControlOrder);
    }
  }


  addSubCategory(index, parentId) {
    this.selectedControl = {};
    if (this.selectedControl) {
      this.selectedControl.parent_control_id = parentId;
      this.selectedControl.is_customizable = true;
      this.selectedControl.is_mandatory = true;
      this.type = 'category';
      this.insertSubHelper(index + 1, this.selectedControl.orders);
    }
  }

  moveUp(control, order) {
    this.editedControl = control;
    this.editedControl.order = order;
    this.controlOrders = this.controlOrders.filter((item) => {
      if (item.order == order) {
        item.order = order + 1;
      }
      if (item.control_id != control.control_id) {
        return item;
      }
    });
    this.editedControl.orders = this.controlOrders;
    this.updateControl();
  }

  changeMoveUp(control, index) {
    let i = index == 0 ? index : index - 1;
    let order = index;
    this.moveHelper(control, i, order);
  }

  changeMoveDown(control, index) {
    let i = index + 1;
    let order = i + 1;
    this.moveHelper(control, i, order);
  }

  moveHelper(control, index, order) {
    this.editedControl = control;
    this.editedControl.order = order;
    let controlLists = [];
    controlLists = _.cloneDeep(this.controlList.filter(item => item.control_id != this.editedControl.control_id));
    controlLists.splice(index, 0, this.editedControl);
    this.editedControl.orders = [];
    controlLists.forEach((item, i) => {
      if (item.control_id != control.control_id) {
        let newControlOrder = { order: i + 1, control_id: item.control_id };
        this.editedControl.orders.push(newControlOrder);
      }
    });
    this.updateControl();
  }

  changeOrder(control, order) {
    this.editedControl = control;
    this.editedControl.order = order;
    // this.controlOrders = this.controlOrders.filter((item) => item.control_id != control.control_id);
    this.controlOrders = this.controlOrders.filter((item) => {
      if (item.order == order) {
        item.order = order - 1;
      }
      if (item.control_id != control.control_id) {
        return item;
      }
    });
    this.editedControl.orders = this.controlOrders;
    this.updateControl();
  }

  createSubControl() {
    this.loader = true;
    try {
      this.standardService.createControl(this.standard_id, this.selectedControl).then(res => {
        this.selectedControl = {};
        if (this.selectedCategoryId) {
          this.getControlByStd(this.selectedCategoryId);
        }
        this.loader = false;
        this.toastr.success('Success', 'Control added');
      }, error => {
        this.loader = false;
        console.log(error);
        this.toastr.error('Error', error.error.error.error_description);
      });
    } catch (error) {
      this.loader = false
      console.log("erro while adding control", error);
      this.toastr.error('Error Occurred', 'Failed to create control');
    }
  }

  createCategory() {
    this.loader = true;
    try {
      this.standardService.createCategory(this.standard_id, this.selectedControl).then(res => {
        this.selectedControl = {};
        this.categoryOrders = [];
        this.getCategoryByStd();
        this.loader = false;
        this.toastr.success('Success', 'Category added');
      }, error => {
        this.loader = false;
        console.log(error);
        this.toastr.error('Error', error.error.error.error_description);
      });
    } catch (error) {
      this.loader = false;
      console.log("erro while adding control", error);
      this.toastr.error('Error Occurred', 'Failed to create category');
    }
  }

  updateCategory() {
    this.loader = true;
    try {
      this.standardService.updateControl(this.selectedCategory.standard_id, this.selectedCategory.control_id, this.selectedCategory).then(res => {
        this.selectedControl = {};
        this.categoryOrders = [];
        this.getCategoryByStd();
        this.loader = false;
        this.toastr.success('Success', 'Category updated');
      }, error => {
        this.loader = false;
        console.log(error);
        this.toastr.error('Error', error.error.error.error_description);
      });
    } catch (error) {
      this.loader = false;
      console.log("erro while adding control", error);
      this.toastr.error('Error Occurred', 'Failed to create category');
    }
  }

  /* validate */
  validate(control) {
    return control.control_no && control.control_tag && control.control_text && control.reference_number;
  }


  updateControlOrder(standard_id, control_id, data) {
    // this.loader = true;
    try {
      this.standardService.updateControl(standard_id, control_id, data).then(res => {
        this.controlOrders = [];
        if (res) {
          setTimeout(() => {
            if (this.selectedCategoryId) {
              this.getControlByStd(this.selectedCategoryId);
            }
          }, 800);
        }
        this.loader = false;
        this.toastr.success('Success', 'Updated control successfully');
      }, error => {
        this.loader = false;
        console.log(error);
        this.toastr.error('Error', error.error.error.error_description);
      });
    } catch (error) {
      this.loader = false;
      console.log('error', error);
      this.toastr.error('Error Occurred', 'Category is not available');
    }
  }

  drops(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      const originalArray = event.container.data;
      this.selectedControl = event.container.data[event.currentIndex];
      // updating orders
      originalArray.forEach((element, index) => {
        this.eventContainerData = element;
        this.eventContainerData.order = index + 1;
        if (this.eventContainerData.control_id !== this.selectedControl.control_id) {
          this.orderArray.push({ order: this.eventContainerData.order, control_id: this.eventContainerData.control_id });
        }
      });
      this.selectedControl.orders = this.orderArray;
      // payload
      this.orderArray = [];

      // update
      this.updateControlOrder(this.selectedControl.standard_id, this.selectedControl.control_id, this.selectedControl);
    }


  }

  /* node data treetable */
  // prepareGroupData(groups) {
  //   if (groups.length > 0) {
  //     groups.forEach((el, index) => {
  //         groups[index].controls = [];
  //         groups[index].controls = el.controls;
  //       if (el.controls && el.controls.length > 0) {
  //         this.prepareGroupData(el.controls);
  //       }
  //     });
  //   }
  //   this.constructNodes();
  // }

  constructNodes() {
    this.nodes = [];
    this.allList = [];
    this.nodes = this.CheckNode(this.categoryList);

  }

  CheckNode(groups: any[], parent?): any[] {
    let temp1 = [];
    if (groups.length > 0) {
      groups.forEach((node, index) => {
        let temp = {
          data: {
            category: node,
            control_text: node.control_text,
            control_id: node.control_id,
            control_no: node.control_no,
            index: index,
            hasChildren: false,
            sub: false,
            parentId: node.parent_control_id,
            orders: [],
            parentOrders: [],
            active: node.control_id != this.selectedCategory.control_id ? false : true
          },
          children: [],
          leaf: true,
          expanded: false
        }
        // temp.data.control_text = node.control_text;
        // this.allList.push(node);
        if (node.controls && node.controls.length > 0) {
          temp.data.hasChildren = true;
          temp.leaf = false;
        }
        if (node.controls && node.controls.length > 0) {
          temp.expanded = true;
          temp.data.sub = true;
          temp.children = this.CheckNode(node.controls, node);
        }
        if (parent && parent.orders) {
          temp.data.parentOrders = parent.orders;
        }
        if (node.orders && node.orders.length > 0) {
          temp.data.orders = node.orders
        }
        temp1.push(temp);
      });
    }
    return temp1;

  }

  nodeexpand(event) {
    // this.constructNodes();
  }

  // activeCate() {
  //   if(this.nodes) {
  //     this.allList = _.map(this.allList, (item) => {
  //       console.log("item", item);
  //       if(item.control_id != this.selectedCategory.control_id) {
  //         item.active = false;
  //       } else {
  //         item.active = true;
  //       }
  //       return item;
  //       // item.active = item.control_id != this.selectedCategory.control_id ? false : true;
  //     })
  //     console.log("item", this.allList, this.selectedCategory);

  //   }
  // }

}
