import { Component, OnInit } from '@angular/core';
import { StandardService } from 'src/app/services/rest/standard.service';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create-standard',
  templateUrl: './create-standard.component.html',
  styleUrls: ['./create-standard.component.scss']
})
export class CreateStandardComponent implements OnInit {
  loader: boolean = false;
  standards: any[] = [];
  standardForm: FormGroup;
  standardId: any;
  payload: any = {};
  standardData: any = {};
  formDisplay: boolean = false;
  is_finalised: boolean = true;
  is_active: boolean = true;

  constructor(private standardService: StandardService, private toastr: ToasterService, private http: HttpClient,
    private fb: FormBuilder, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getRouteParam();
  }

  getRouteParam() {
    this.route.queryParams.subscribe(res => {
      this.standardId = res.name;
    })
    this.route.params.subscribe(res => {
      this.standardId = res.standard_id;
      if (this.standardId) {
        this.getStandardById();
      } else {
        this.setForm();
      }
    })
  }

  getStandardById() {
    this.standardService.getStandardById(this.standardId).subscribe(res => {
      if (res) {
        this.standardData = res.data;
        this.is_finalised = this.standardData.is_finalised;
        this.is_active = this.standardData.is_active;
        this.setForm();
      } else {
        this.setForm();
      }
    }, err => {
      this.setForm();
    });
  }

  setForm() {
    this.standardForm = this.fb.group({
      standard_name: new FormControl(this.standardData.standard_name || '', Validators.required),
      version: new FormControl(this.standardData.version || ''),
      description: new FormControl(this.standardData.description || ''),
      // is_finalised : new FormControl(this.standardData.is_finalised ? this.standardData.is_finalised : true),
      // is_active : new FormControl(this.standardData.is_active ? this.standardData.is_active : true)
    });
    this.formDisplay = true;
  }

  setPayload() {
    let data = {
      is_finalised: this.is_finalised,
      is_active: this.is_active
    };
    this.payload = { ...this.standardForm.value, ...data };
  }

  setStandard() {
    this.setPayload();
    this.standardService.createStandard(this.payload).subscribe(res => {
      if (res) {
        this.standardId = res.data.id;
        this.toastr.success('Success', 'Created standard successfully');
        setTimeout(() => {
          this.goToControls(this.standardId);
        }, 1000);
      } else {
        this.goToControls();
      }
    });
  }

  deleteStandard() {
    let data = {
      is_deleted: true,
      is_finalised: this.standardData.is_finalised,
    }
    let deletePayload = { ...data, ...this.standardForm.value };
    this.standardService.updateStandard(this.standardId, deletePayload).subscribe(res => {
      if (res) {
        this.standardId = res.data.id;
        this.toastr.success('Success', 'Updated standard successfully');
        setTimeout(() => {
          this.goToList();
        }, 1000);
      } else {
        this.goToList();
      }
    }, err => {

    });
  }

  updateStandard() {
    this.setPayload();
    this.standardService.updateStandard(this.standardId, this.payload).subscribe(res => {
      if (res) {
        this.standardId = res.data.id;
        this.toastr.success('Success', 'Updated standard successfully');
        setTimeout(() => {
          this.goToList();
        }, 1000);
      } else {
        this.goToList();
      }
    }, err => {

    });

  }

  goToList() {
    if (this.standardId) {
      this.router.navigate(['../../list'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../list'], { relativeTo: this.route });
    }
  }

  goToControls(id?) {
    if (id) {
      this.router.navigate(['../controls', id], { relativeTo: this.route });
    } else {
      this.router.navigate(['../controls'], { relativeTo: this.route });
    }
  }
}
