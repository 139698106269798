import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Cache } from 'src/app/services/helpers/storage.provider';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { Constants } from 'src/app/services/rest/Constants';
import { OthersService } from 'src/app/services/rest/others.service';

@Component({
  selector: 'app-add-logo',
  templateUrl: './add-logo.component.html',
  styleUrls: ['./add-logo.component.scss']
})
export class AddLogoComponent implements OnInit {

  isSpinner: boolean = false;
  loader: boolean = false
  addedLogoTenantList: any[] = [
    // { name: 'Nightly', path: '../../../assets/client-logo/nightly.png', id: 1, _id: 1 },
  ];
  tenantList: any[] = []
  selectedTenant: any = { _id: 0 };
  tenantSelected: any
  file: File = null;

  constructor(private toastr: ToasterService, private otherService: OthersService) { }

  ngOnInit(): void {
    this.getAllTenantLogo()
    this.getTenantsList()
  }
  getAllTenantLogo() {
    this.loader = true
    try {
      this.otherService.getAllTenantsLogo().then(res => {
        if (res.code == 200) {
          this.addedLogoTenantList = []
          this.reset()
          res.data.forEach((e,i)=>{
            if(e.TenantLogo.length>0){
              this.addedLogoTenantList.push(e)
            }
          })
          // this.addedLogoTenantList = res.data;
          console.log(this.addedLogoTenantList);
        }
        this.loader = false
      }, error => {
        this.loader = false
        this.toastr.error('Error Occurred', error);
      });
    }
    catch (error) {
      this.loader = false
      this.toastr.error('Error Occurred', 'Tenants are not available');
    }
  }
  getTenantsList() {
    this.loader = true
    try {
      this.otherService.getAllTenants().then(res => {
        if (res.code == 200) {
          this.tenantList = []
          this.reset()
          res.data.forEach((e,i)=>{
            if(e.TenantLogo.length==0){
              this.tenantList.push(e)
            }
          })
          // this.tenantList = res.data;
        }
        this.loader = false
      }, error => {
        this.loader = false
        this.toastr.error('Error Occurred', error);
      });
    }
    catch (error) {
      this.loader = false
      this.toastr.error('Error Occurred', 'Tenants are not available');
    }
  }
  onChange(event) {
    let size = this.returnFileSize(event.target.files[0].size)
    size = size.replace(/\D./g,'')
    console.log(size);
    this.file = event.target.files[0];
  }
  returnFileSize(number) {
    if(number < 1024) {
      return number + 'bytes';
    } else if(number >= 1024 && number < 1048576) {
      return (number/1024).toFixed(1) + 'KB';
    } else if(number >= 1048576) {
      return (number/1048576).toFixed(1) + 'MB';
    }
  }
  addLogo() {
    const fd = new FormData();
    fd.set('file', this.file, this.file.name);
    console.log(this.file);

    this.loader = true

    try {
      this.otherService.addTenantLogo(fd, this.selectedTenant._id).then(res => {
        if (res.code == 200) {
          console.log(res);
          this.toastr.success('Success', 'Tenant Logo added successfully');
          this.reset()
          this.getAllTenantLogo()
          this.getTenantsList()
          this.loader = false
        }
      }, error => {
        this.loader = false
        this.toastr.error('Error Occurred', error);
      });
    }
    catch (error) {
      this.loader = false
      this.toastr.error('Error Occurred', 'Tenant Logo are not added');
    }

  }
  updateTenantWithLogo() {
    const fd = new FormData();
    fd.set('file', this.file, this.file.name);

    let imgId = this.selectedTenant.TenantLogo[0]._id
    this.loader = true

    try {
      this.otherService.updateTenantLogo(fd, this.selectedTenant._id,imgId).then(res => {
        if (res.code == 200) {
          console.log(res);
          this.toastr.success('Success', 'Tenant Logo updated successfully');
          this.reset()
          this.getAllTenantLogo()
          this.getTenantsList()
          this.loader = false
        }
      }, error => {
        this.loader = false
        this.toastr.error('Error Occurred', error);
      });
    }
    catch (error) {
      this.loader = false
      this.toastr.error('Error Occurred', 'Tenant Logo are not added');
    }

  }
  deleteTenant() {
    this.loader = true
    try {
      this.otherService.deleteTenantLogo(this.selectedTenant._id).then(res => {
        if (res.code == 200) {
          console.log(res);
          this.toastr.success('Success', 'Tenant with Logo Deleted successfully');
          this.reset()
          this.getAllTenantLogo()
          this.getTenantsList()
          this.loader = false
        }
      }, error => {
        this.loader = false
        this.toastr.error('Error Occurred', error);
      });
    }
    catch (error) {
      this.loader = false
      this.toastr.error('Error Occurred', 'Tenants are not Deleted');
    }
  }
  reset() {
    this.selectedTenant = { _id: 0 }
    this.tenantSelected = {};
    (<HTMLInputElement>document.getElementById('uploadFile')).value = "";
    (<HTMLInputElement>document.getElementById('uploadFileUpdate')).value = "";
    this.file = null    
  }
  getLogoUrl(data) {
    let attachmentPath = data.TenantLogo[data.TenantLogo.length-1].attachment_path
    var downloadUrl = Constants.GetBaseURL() + 'files/' + attachmentPath +
      '?access_token=' + localStorage.getItem('access_token') + '&filename=' +
      attachmentPath
    return downloadUrl
  }
}
