import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { StandardService } from 'src/app/services/rest/standard.service';
import { ToasterService } from 'src/app/services/helpers/toaster.service';
import { RiskMgmtService } from 'src/app/services/rest/risk-mgmt.service';

@Component({
  selector: 'app-create-risk',
  templateUrl: './create-risk.component.html',
  styleUrls: ['./create-risk.component.scss']
})
export class CreateRiskComponent implements OnInit {
  riskForm: FormGroup;
  riskId: any;
  formDisplay: boolean = false;
  riskData: any = {};
  businessList: any[] = [
    // { id: 1, value: 100, name: 'IT Firms' },
    // { id: 2, value: 101, name: 'Banking' },
    // { id: 3, value: 102, name: 'IT Consultancy' },
    // { id: 4, value: 103, name: 'Government' },
  ];
  business_types: any[] = [];
  controls: any[] = [
    { name: '1(A) Governance: Training and eduction Security awareness', version: '123', std: 'SEBI', }
  ];
  ownerList: any[] = [
    { name: 'Kinnari', sub: '1234', id: '1' },
    { name: 'Astha', sub: '1234', id: '2' },
  ];
  standardList: any[] = [];
  owner: any;
  owners: any[] = [];
  selectedStandard: any;
  domainId: any;
  domain: any;

  constructor(private standardService: StandardService, private riskService: RiskMgmtService, private fb: FormBuilder, private toastr: ToasterService,
    private router: Router, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.getBusinessType();
    this.getRouteParam();
    // this.getMasterStandards();
  }

  getRouteParam() {
    this.route.params.subscribe(res => {
      if (res && res.riskId) {
        this.riskId = res.riskId;
        this.getRiskById();
      }
    })
    this.route.queryParams.subscribe(res => {
      if (res && res.domainId) {
        this.domainId = res.domainId;
        this.getDomainbyId();
      }
    });
  }

  getDomainbyId() {
    this.riskService.getDomainById(this.domainId).subscribe(res => {
      this.domain = res.data;
      this.setForm();
    }, error => {
      this.setForm();
      console.log("error", error);
    })
  }

  checkStandard(business, event) {
    if (event.target.checked) {
      business.active = true;
      this.business_types.push(business);
    } else {
      business.active = false;
      _.remove(this.business_types, business);
    }
  }

  async getMasterStandards() {
    try {
      this.standardService.getAllMasterStandard().then(res => {
        if (res) {
          this.standardList = res.data;
        }
      }, error => {
        console.log("error", error);
      });
    }
    catch (error) {
      console.log(error);
      this.toastr.error('Error Occurred', 'Standards are not available');
    }
  }

  setForm() {
    let domainList = [];
    domainList.push(this.domain ? this.domain : {});
    this.riskForm = this.fb.group({
      title: new FormControl(this.riskData.title || '', Validators.required),
      risk: new FormControl(this.riskData && this.riskData.risk || '', Validators.required),
      // vulnerability: new FormControl(this.riskData && this.riskData.vulnerability || ''),
      // threat: new FormControl(this.riskData.threat || '', Validators.required),
      risk_source: new FormControl(this.riskData && this.riskData.risk_source || ''),
      business_types: this.business_types,
      domains: domainList
    });
    this.formDisplay = true;
  }

  getRiskById() {
    this.riskService.fetchRiskById(this.riskId).subscribe(res => {
      this.riskData = res.data;
      this.business_types = res.data.business_types;
      this.businessList = _.map(this.businessList, (item) => {
        item.active = _.find(this.riskData.business_types, ['name', item.name]) ? true : false;
        return item;
      });
      this.setForm();
    }, error => {
      // this.toastr.error('Error', '');
    });
  }

  createRisk() {
    this.riskForm.value.business_types = this.business_types;
    this.riskService.createRisk(this.riskForm.value).subscribe(res => {
      this.toastr.success('Success', 'Risk created successfully');
      this.router.navigate(['../domains'], { relativeTo: this.route });
    }, error => {
      this.toastr.error('Error', error.error.error.error_description);
    });
  }

  updateRisk() {
    this.riskForm.value.business_types = this.business_types;
    this.riskForm.value.domains = this.riskData.domains;

    this.riskService.updateRisk(this.riskId, this.riskForm.value).subscribe(res => {
      this.toastr.success('Success', 'Risk update successfully');
      this.router.navigate(['../../domains'], { relativeTo: this.route });
    }, error => {
      this.toastr.error('Error', error.error.error.error_description);
    });
  }

  getBusinessType() {
    this.riskService.fetchThemes().subscribe(res => {
      this.businessList = res.data;
    }, error => {
      this.toastr.error('Error', error.error.error.error_description);
      // this.toastr.error('Error', error.error.error.error_description);
    });
  }
  // multiselect
  //  multiselection for Owner
  valueChange(value) { }

  onOwnerSelect(item: any) {
    this.owners.push({ name: item.name, unique_id: item.id });
    if (this.owners.length !== 0) {
      // document.getElementById('ErrorDomain').style.visibility = 'hidden';
    }
  }

  allOweners(items: any) {
    items.forEach(item => {
      this.owners.push({ name: item.name, unique_id: item.id });
    });
  }

  noOwner(items: any) {
    this.owners = [];
  }

  onOwnerDeSelect(item: any) {
    this.owners = _.remove(this.owners, (audit) => {
      return audit.unique_id != item.id;
    });
    if (this.owners.length === 0) {
      // document.getElementById('ErrorDomain').style.visibility = 'visible';
    }
  }

  gotoRisk() {
    this.router.navigate(['../../complyment/risks/domains']);
  }
}
