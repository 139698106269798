
export class LocationHelper {

    static getOrigin() {
        if (location.origin) {
            return location.origin;
        } else {
            const protocol = location.protocol;
            const host = location.host;
            const port = location.port;
            return protocol + "//" + host + (port ? ":" + port : "");
        }
    }


}