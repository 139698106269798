import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Constants } from 'src/app/services/rest/Constants';
import { LocationHelper } from 'src/app/services/helpers/LocationHelper';
import moment from 'moment';
import { Cache } from 'src/app/services/helpers/storage.provider';
import { UsersService } from 'src/app/services/rest/users.service';


@Component({
  selector: 'app-data-loader',
  templateUrl: './data-loader.component.html',
  styleUrls: ['./data-loader.component.scss']
})
export class DataLoaderComponent implements OnInit {

  @Cache({ pool: 'User' }) userInfo: any;
  @Cache({ pool: 'UserDetail' }) userDetail: any;
  @Cache({ pool: 'role' }) role: any;

  token: any;
  parseToken: any;
  exp: any;

  constructor(private router: Router, private userService: UsersService) {
    if (localStorage.getItem('access_token')) {
      this.token = localStorage.getItem('access_token');
      this.toParseToken();
    } else {
      this.redirectLogin();
    }
  }

  ngOnInit(): void {
    if (this.token && this.isAuthenticated()) {
      this.setUserData();
    } else {
      this.redirectLogin();
    }
  }

  /** parse token if token is not null else redirect to login page */
  toParseToken() {
    if (this.token && this.token != ' ') {
      this.parseToken = JSON.parse(atob(localStorage.getItem('access_token').split('.')[1]));
      // console.log("token", this.parseToken);
    } else {
      this.redirectLogin();
    }
  }

  /** check if token is expire */
  isAuthenticated() {
    this.exp = new Date(this.parseToken.exp * 1000);
    return !moment(this.exp).isBefore();
  }

  redirectLogin() {
    // localStorage.clear();
    const auth_base_url = Constants.GetBaseURL();
    const portal_base_url = LocationHelper.getOrigin();
    window.location.href = auth_base_url + "login-srv/login";
    // demo-api.complyment.com/login-srv/login
  }

  setUserData() {
    let groups = [];
    var sub = this.parseToken.sub;
    groups = this.parseToken.groups ? this.parseToken.groups : [];
    this.userInfo = {
      sub: sub, groups: JSON.stringify(groups), name: this.parseToken.name
    };
    this.userDetail = { name: this.parseToken.name, sub: sub, groups: JSON.stringify(groups) };
    // console.log("userinfo data loader", this.userInfo, groups);
    this.router.navigate(['/complyment']);
  }

  getUserDetail() {
    try {
      let data = {
        sub: this.parseToken.sub
      }
      this.userService.getUserInfoBySub(data).then(res => {
        // console.log("user infov",  res);
        if (res) {
          this.userDetail = res.data;
          this.router.navigate(['/complyment']);
        } else {
          this.router.navigate(['/complyment']);
        }
      });
    } catch (error) {
      console.log("error while getting user", error);
      this.router.navigate(['/complyment']);
    }
  }

  checkRole(groups) {
    groups.forEach(group => {
      group.roles.forEach(role => {
        this.role = role;
      });
    });
  }

}
