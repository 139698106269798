import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import * as uuid from 'uuid';
import { CookieStore } from '../helpers/CookieStore';


@Injectable({
  providedIn: 'root'
})
export class SockethelperService {

  private socket;
  private url = "https://socket-hub.complyment.com";
  constructor() {
    /* this.socket = io(this.url, {
      transports: ['websocket']
    });
    this.socket.on('reconnect_attempt', () => {
      this.socket.io.opts.transports = ['polling', 'websocket'];
    }); */
  }

  sendMessage(msg: any) {
    // this.socket.emit("msgToServer", msg);
  }

  joinRoom() {
    const room = uuid.v4();
    const headers: any = {

    }

    const token = CookieStore.getBearerToken();
    if (token) {
      headers.authorization = token;
    }
    const df = {
      room: room,
      headers: headers
    }
    // this.socket.emit("joinroom", df);
    return df;
  }

  async awaitForResult(room_info: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // this.socket.on("uievent-" + room_info.room, (data) => {
      //   console.log("data from socket", data);
      //   this.socket.emit("leaveroom", {
      //     room: room_info.room,
      //     headers: room_info.headers
      //   });
      //   resolve(data);
      // })
    })
  }

  async leaveRoom(room_info: any): Promise<any> {
    // this.socket.emit("leaveroom", {
    //   room: room_info.room,
    //   headers: room_info.headers
    // });
  }

}
