import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import owner from '../owner.json';
import auditor from '../auditor.json';
import consult from '../consultant.json';
import * as _ from 'lodash';
import { Cache } from './storage.provider';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  @Cache({ pool: 'User' }) user: any;
  @Cache({ pool: 'role' }) role: any;
  groups: any;
  // role:any;
  ownerMenu: any =  owner;
  auditorMenu: any = auditor;
  consultMenu: any = consult;

  constructor() {
  }

  public getJSON(): Observable<any> {
    return of(this.ownerMenu).pipe();

  }
}
