<div class="main-header sticky side-header nav nav-item">
  <div class="container-fluid justify-content-between">
    <div>
      <div class="responsive-logo">
        <a [routerLink]="['/load']"><img src="assets/img/logo1.png" class="logo-1" alt="logo"></a>
        <!-- <a href="index.html"><img src="assets/img/brand/logo-white.png" class="dark-logo-1" alt="logo"></a>
          <a href="index.html"><img src="assets/img/brand/favicon.png" class="logo-2" alt="logo"></a>
          <a href="index.html"><img src="assets/img/brand/favicon.png" class="dark-logo-2" alt="logo"></a> -->
      </div>
      <div class="app-sidebar__toggle" data-toggle="sidebar">
        <a class="open-toggle" href="#"><i class="header-icon fe fe-align-left"></i></a>
        <a class="close-toggle" href="#"><i class="header-icons fe fe-x"></i></a>
      </div>
    </div>
      <div class="main-header-left ">

        <div class="main-header-center ml-3 d-sm-none mg-0 d-md-none d-lg-block">
          <input class="form-control" placeholder="Search for anything..." type="search">
          <button class="btn"><i class="fas fa-search d-none d-md-block"></i></button>
        </div>
      
      <div class="main-header-right">
        <ul class="nav">
          <li class="">
          </li>
        </ul>
        <div class="nav nav-item  navbar-nav-right ml-auto">
          
          <div class="dropdown main-profile-menu nav nav-item nav-link">
            <a class="profile-user d-flex" data-toggle="dropdown" href="">
              <img alt="" src="../../../assets/img/faces/6.jpg"></a>
            <div class="dropdown-menu">
              <div class="main-header-profile bg-primary p-3">
                <div class="d-flex wd-100p">
                  <!-- <div class="main-img-user"><img alt="" src="../../../assets/img/faces/6.jpg" class=""></div> -->
                  <div class="my-auto">
                    <h6>{{username}}</h6>
                  </div>
                </div>
              </div>
              <a class="dropdown-item" [routerLink]="['/auth']"><i class="bx bx-log-out"></i> Sign Out</a>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
<!-- Sidebar-right-->

<!--/Sidebar-right-->